import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from '../shared/services/api/api.service';
import { HttpService } from '../shared/services/http/http.service';
import { Observable } from 'rxjs';


@Injectable()
export class NotificationService extends ApiService{
  public messages: Subject<any> = new Subject<any>();

  constructor(
      protected http: HttpService
  ) {
        super(http);
        this.url = 'api/notifications';
  }

  visto(id:number): Observable<any> {
    return this.http.put(`${this.url}/${id}/visto`);
  }

  vistoAll(): Observable<any> {
    return this.http.put(`${this.url}/visto/all`);
  }

  newNotifications(){
      return this.http.get(`${this.url}/nuevas`);
  }
}

