import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-select-plan',
  templateUrl: './select-plan.component.html',
  styleUrls: ['./select-plan.component.scss']
})
export class SelectPlanComponent implements OnInit {

  public selectedPlan: any;

  @Input() inputPlan?: any[];
  @Output() planChange: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    if (this.inputPlan)
      this.selectedPlan = this.inputPlan;
  }

  public onPlanSelected(plan: any): void {
    if (this.selectedPlan != plan) {
      this.selectedPlan = plan;
      this.planChange.emit(plan);
    }
  }
}
