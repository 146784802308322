import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { isNullOrUndefined, isUndefined } from "util";
import { HttpClient, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';

@Injectable()
export class HttpService {

    constructor(@Inject('Parameters') private parameters: string, protected http: HttpClient) { }

    get(url: string, options?: any, noToken?: boolean, withoutCompanyId?: boolean): Observable<any> {
        return this.http.get(this.getUrl() + url, { headers: this.addAuthHeader(noToken), params: this.addAuthParams(options, withoutCompanyId) });
    }

    post(url: string, body?: any, noToken?: boolean, withoutCompanyId?: boolean): Observable<any> {
        return this.http.post(this.getUrl() + url, body, { headers: this.addAuthHeader(noToken), params: this.addAuthParams({}, withoutCompanyId) });
    }

    put(url: string, body?: any, noToken?: boolean, withoutCompanyId?: boolean): Observable<any> {
        return this.http.put(this.getUrl() + url, body, { headers: this.addAuthHeader(noToken), params: this.addAuthParams({}, withoutCompanyId) });
    }

    delete(url: string, body?: any, options?: any, noToken?: boolean, withoutCompanyId?: boolean): Observable<any> {
        return this.http.delete(this.getUrl() + url, { headers: this.addAuthHeader(noToken), params: this.addAuthParams(options, withoutCompanyId) });
    }

    deleteWithBody(url: string, body?: any, options?: any, noToken?: boolean, withoutCompanyId?: boolean): Observable<any> {
        return this.http.request('delete', this.getUrl() + url, { headers: this.addAuthHeader(noToken), body, params: this.addAuthParams(options, withoutCompanyId) });
    }

    getFile(url: string, options?: any, noToken?: boolean, withoutCompanyId?: boolean): Observable<any> {
        return this.http.get(this.getUrl() + url, { headers: this.addAuthHeader(noToken), params: this.addAuthParams(options, withoutCompanyId), responseType: 'blob' });
    }

    addAuthHeader(noToken?: boolean): HttpHeaders {

        const ACCESS_TOKEN_KEY = 'access_token';
        const AUTHORIZATION_HEADER = `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`;

        let headers = noToken === undefined || noToken === false ? new HttpHeaders().set('Authorization', AUTHORIZATION_HEADER) : new HttpHeaders();

        if (!localStorage.getItem(ACCESS_TOKEN_KEY) || headers.get('Authorization') === null) {
            headers = new HttpHeaders();
        }

        return headers;
    }

    addAuthParams(options: any, withoutCompanyId = false): HttpParams {
        let params = new HttpParams();

        if (options) {
            for (const key of Object.keys(options)) {
                params = params.append(key, options[key]);
            }
        }

        params = params.set('with_company', withoutCompanyId ? '0' : '1');

        return params;
    }

    getUrl() {
        return this.parameters['API_URL'];
    }
}