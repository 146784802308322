import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalUrlStorage {

    constructor() {
    }

    save(localUrl) {

        if (localUrl)
            localStorage.setItem('localUrl', JSON.stringify(localUrl));
    }

    get() {
        return JSON.parse(localStorage.getItem('localUrl'));
    }

    remove() {
        localStorage.removeItem('localUrl');
    }
}
