import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
    {
        path: '',
        title: 'Dashboard',
        icon: 'mdi mdi-view-dashboard',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: [],
        hideRoles: []
    },
    {
        path: '/reviews',
        title: 'Your Reviews',
        icon: 'mdi mdi-message-text',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: [],
        hideRoles: []
    },
    {
        path: '/sms/create',
        title: 'Send A Review Invite',
        icon: 'mdi mdi-message-text-outline',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: [],
        hideRoles: []
    },
    {
        path: '/leaderboard',
        title: 'Leaderboard',
        icon: 'mdi mdi-sitemap',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: [],
        hideRoles: []
    },
    {
        path: '',
        title: 'Manage',
        icon: 'mdi mdi-settings',
        class: 'has-arrow',
        ddclass: '',
        extralink: false,
        hideRoles: ['ROLE_EMPLOYEE'],
        submenu: [
            // {
            //     path: '/accounts',
            //     title: 'Create Account',
            //     icon: 'mdi mdi-account-multiple-plus',
            //     class: '',
            //     ddclass: '',
            //     extralink: false,
            //     submenu: [],
            //     hideRoles: ['ROLE_EMPLOYEE', 'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_ENTERPRISE']
            // },
            {
                path: '',
                title: 'Users',
                icon: 'mdi mdi-account',
                class: 'has-arrow',
                ddclass: '',
                extralink: false,
                submenu: [
                    {
                        path: '/users/create/super',
                        title: 'Create User',
                        icon: 'mdi mdi-account-plus',
                        class: '',
                        ddclass: '',
                        extralink: false,
                        submenu: [],
                        hideRoles: ['ROLE_EMPLOYEE', 'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_ENTERPRISE']
                    },
                    {
                        path: '/users/admin',
                        title: 'Admin',
                        icon: 'mdi mdi-account',
                        class: '',
                        ddclass: '',
                        extralink: false,
                        submenu: [],
                        hideRoles: ['ROLE_EMPLOYEE', 'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_ENTERPRISE']
                    },
                    {
                        path: '/users/enterprise',
                        title: 'Enterprise',
                        icon: 'mdi mdi-account',
                        class: '',
                        ddclass: '',
                        extralink: false,
                        submenu: [],
                        hideRoles: ['ROLE_EMPLOYEE', 'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_ENTERPRISE']
                    }
                ],
                hideRoles: ['ROLE_EMPLOYEE', 'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_ENTERPRISE'],
            },
            {
                path: '/users',
                title: 'Team',
                icon: 'mdi mdi-account-multiple',
                class: '',
                ddclass: '',
                extralink: false,
                submenu: [],
                hideRoles: ['ROLE_EMPLOYEE']
            },
            {
                path: '/customers',
                title: 'Customers',
                icon: 'mdi mdi-account',
                class: '',
                ddclass: '',
                extralink: false,
                submenu: [],
                hideRoles: ['ROLE_EMPLOYEE', 'ROLE_MANAGER']
            },
            {
                path: '/templates/create',
                title: 'Messages',
                icon: 'mdi mdi-message-image',
                class: '',
                ddclass: '',
                extralink: false,
                submenu: [],
                hideRoles: ['ROLE_EMPLOYEE', 'ROLE_MANAGER']
            },
            {
                path: '/auth/platform',
                title: 'Apps',
                icon: 'mdi mdi-arrow-top-right',
                class: '',
                ddclass: '',
                extralink: false,
                submenu: [],
                hideRoles: ['ROLE_EMPLOYEE', 'ROLE_MANAGER']
            },
            {
                path: '/messenger',
                title: 'Messenger',
                icon: 'mdi mdi-comment-processing-outline',
                class: '',
                ddclass: '',
                extralink: false,
                submenu: [],
                hideRoles: ['ROLE_EMPLOYEE', 'ROLE_SUPER', 'ROLE_ENTERPRISE']
            },
            {
                path: '/gmb',
                title: 'GMB Audit',
                icon: 'mdi mdi-google',
                class: '',
                ddclass: '',
                extralink: false,
                submenu: [],
                hideRoles: ['ROLE_SUPER', 'ROLE_ENTERPRISE', 'ROLE_EMPLOYEE', 'ROLE_MANAGER']
            },
            {
                path: '/billing',
                title: 'Billing',
                icon: 'mdi mdi-cash',
                class: '',
                ddclass: '',
                extralink: false,
                submenu: [],
                hideRoles: ['ROLE_EMPLOYEE', 'ROLE_MANAGER', 'ROLE_SUPER'],
                hideIsAgency: true
            },
            {
                path: '/industry',
                title: 'Industry',
                icon: 'mdi mdi-factory',
                class: '',
                ddclass: '',
                extralink: false,
                submenu: [],
                hideRoles: ['ROLE_EMPLOYEE', 'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_ENTERPRISE']
            },
            {
                path: '/promo-code',
                title: 'Promo Code',
                icon: 'mdi mdi-cash-multiple',
                class: '',
                ddclass: '',
                extralink: false,
                submenu: [],
                hideRoles: ['ROLE_EMPLOYEE', 'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_ENTERPRISE']
            },
            // {
            //     path: '',
            //     title: 'NMS',
            //     icon: 'mdi mdi-settings',
            //     class: 'has-arrow',
            //     ddclass: '',
            //     extralink: false,
            //     hideRoles: ['ROLE_EMPLOYEE', 'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_ENTERPRISE'],
            //     submenu: [
            //         {
            //             path: '/nms-notification',
            //             title: 'Notification',
            //             icon: 'mdi mdi-cash-multiple',
            //             class: '',
            //             ddclass: '',
            //             extralink: false,
            //             submenu: [],
            //             hideRoles: ['ROLE_EMPLOYEE', 'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_ENTERPRISE']
            //         },
            //         {
            //             path: '/nms-template',
            //             title: 'Template',
            //             icon: 'mdi mdi-cash-multiple',
            //             class: '',
            //             ddclass: '',
            //             extralink: false,
            //             submenu: [],
            //             hideRoles: ['ROLE_EMPLOYEE', 'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_ENTERPRISE']
            //         }
            //     ],
            // }
        ],
    },
];
