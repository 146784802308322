import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class RoleStorage {

    constructor() {
    }

    save(role: string) {
        localStorage.setItem('role', role);
    }

    get() {
        return localStorage.getItem('role');
    }

    remove() {
        localStorage.removeItem('role');
    }

    isSuperiorPermission() {
        let role: string = this.get();

        if (role == "ROLE_SUPER" || role == "ROLE_ENTERPRISE")
            return true;

        return false;
    }
}
