import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api/api.service';
import { HttpService } from '../shared/services/http/http.service';
import { Observable } from 'rxjs';

@Injectable()
export class CompanyService extends ApiService {
    constructor(
        protected http: HttpService
    ) {
        super(http);
        this.url = 'api/companies';
    }

    getOffices(id: number): Observable<any> {
        return this.http.get(`${this.url}/${id}/offices`);
    }

    syncLocation(id: number): Observable<any> {
        return this.http.get(`${this.url}/${id}/import-locations`);
    }

    syncLocationByPlaceId(locations: any, id: number, withOutCompany?: boolean): Observable<any> {
        return this.http.get(`${this.url}/${id}/import-locations`, this.serialize(locations), false, (withOutCompany) ? true : false);
    }

    showLocation(params: any, id: number): Observable<any> {
        return this.http.get(`${this.url}/${id}/show-locations`, super.serialize(params), false, true);
    }

    rotate(id: number, withOutCompany?: boolean): Observable<any> {
        return this.http.put(`${this.url}/${id}/rotate`, { direction: 'next' }, false, (withOutCompany) ? true : false);
    }

    ApiLink(withOutCompany?: boolean): Observable<any> {
        return this.http.get(`https://gateway.2stepreviews.com/api/stats/get_tech_data`, {}, false, (withOutCompany) ? true : false);
    }

    collect(id: any, withOutCompany?: boolean): Observable<any> {
        return this.http.get(`${this.url}/collect/${id}`, {}, false, (withOutCompany) ? true : false);
    }

    changeIndustry(industry_id: number): Observable<any> {

        let params = {
            industry_id: industry_id
        };

        return this.http.put(`${this.url}/industry`, params);
    }

    uploadLogo(logo: any, id): Observable<any> {
        return this.http.post(`${this.url}/${id}/logo`, logo);
    }

    editGoal(company_id: any, goal: any): Observable<any> {

        let params = {
            goal: goal
        };

        return this.http.put(`${this.url}/${company_id}/goal`, params);
    }

    getGoalStatus(company_id: number): Observable<any> {
        return this.http.get(`${this.url}/goal/${company_id}`);
    }

    countOffices(): Observable<any> {
        return this.http.get(`${this.url}/offices-count`);
    }

    availableCompany(name): Observable<any> {
        return this.http.get(`${this.url}/available`, { q: name }, true);
    }

    getQuota(company_id: any): Observable<any> {
        return this.http.get(`${this.url}/${company_id}/quota`);
    }

    getPlan(company_id: any): Observable<any> {
        return this.http.get(`${this.url}/${company_id}/plan`, null, false, true);
    }

    listCompanies(params?: any): Observable<any> {

        params = this.serialize(params);
        return this.http.get(this.url, params, false, true);
    }

    getRate(company_id: any): Observable<any> {
        return this.http.get(`${this.url}/${company_id}/rate`, {}, false, true);
    }

    updateIndustry(params){
        return this.http.put(`${this.url}/industry`, this.serialize(params));
    }
}
