import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AppService } from '../services/app/app.service';
import { AuthService } from '../../authentication/auth.service';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from '../../notification/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from '../services/app/util.service';
import { take } from 'rxjs/operators';
import { UsuarioService } from 'src/app/usuario/usuario.service';
import { UsuarioStorage } from 'src/app/usuario/usuario.storage';
import { CompanyStorage } from 'src/app/company/company.storage';
declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};
  public notifications;
  public notification;
  public loading_notifications: boolean = false;
  public first_request: boolean = false;
  public last_page: boolean;

  public filters_notifications: any = {
    limit: 20,
    offset: 0,
  };

  public showSearch = false;

  constructor(
    private modalService: NgbModal,
    public appService: AppService,
    public authService: AuthService,
    public fb: FormBuilder,
    public router: Router,
    public notificationService: NotificationService,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    public utilService: UtilService,
    public usuarioService: UsuarioService,
    public usuarioStorage: UsuarioStorage,
    public companyStorage: CompanyStorage
  ) {
    this.last_page = true;
    this.notifications = [];
  }

  ngOnInit() {
    this.hayNuevasNotificaticaciones();
  }

  logout() {
    this.authService.logout();
  }

  getNotifications(first_request: boolean) {

    if (!first_request) {
      this.filters_notifications.offset++;
    }
    else {
      this.filters_notifications.offset = 0;
      this.notifications = [];
    }

    this.notificationService.list(this.filters_notifications).subscribe((response) => {
      response.data.results.forEach((item) => {
        this.notifications.push(item);
      });
      this.isLastPage(response.data.paging);
    }, (error) => {
      this.toastr.error(error.error.error.message, 'Error!');
    });
  }

  deleteNotification(notification: any, index: any) {
    this.spinner.show();
    this.notificationService.delete(notification.id).subscribe((response) => {
      this.spinner.hide();
      this.notifications.splice(index, 1);
      this.hayNuevasNotificaticaciones();
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(error.error.error.message, 'Error!');
    });
  }

  openNotificationModal(content, notification, index) {

    this.notification = notification;

    this.notificationService.visto(notification.id).subscribe((response) => {
      this.notifications[index].status = false;

      this.modalService.open(content).result.then(
        result => {

          if (notification.payload)
            this.router.navigate(['/messenger', { payload: notification.payload }]);

          if (notification.link)
            this.redirectTo(notification.link)
        },
        reason => { }
      );

      this.hayNuevasNotificaticaciones();
    }, (error) => {
      this.toastr.error(error.error.error.message, 'Error!');
    });
  }

  hayNuevasNotificaticaciones() {
    this.appService.hayNuevasNotificaticaciones();
  }

  checkAllNotifications() {
    this.notificationService.vistoAll().subscribe((response) => {
      this.hayNuevasNotificaticaciones();
      this.getNotifications(true);
    }, (error) => {
      this.toastr.error(error.error.error.message, 'Error!');
    });
  }

  isLastPage(paging) {
    if (this.notifications.length < paging.total)
      this.last_page = false;
    else
      this.last_page = true;
  }

  redirectTo(link: any) {

    let new_link;

    if (link.includes("#"))
      new_link = link.substr(link.indexOf('#') + 1, link.length);

    if (new_link)
      this.router.navigateByUrl(new_link);
  }
}
