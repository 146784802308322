<div class="card-body">
    <br>
    <div class="chat-box scrollable" style="height:400px;" [perfectScrollbar]="config">
        <p><b>2stepreviews.com</b> (https://www.2stepreviews.com), including all of its related applications,
            dashboards or platforms (individually and collectively, the “Website”), is owned and operated by 2
            Step Reviews LLC (“2 Step”, “we” or “us”). By using or accessing the Website or 2 Step Service(s),
            by signing or clicking to accept any Subscription Documentation referencing these Terms, or by
            clicking to accept these Terms, you agree to all the terms and conditions of this Terms of Service
            Agreement (“Terms”, “Terms of Service” or “Agreement”).
            If you are using a 2 Step Service on behalf of a company or other entity, then “Client” or “you”
            means that entity, and you are binding that entity to this Agreement. You represent and warrant that
            you have the legal power and authority to enter into this Agreement and that, if the Client is an
            entity, this Agreement is entered into by an employee or agent with all necessary authority to bind
            that entity to this Agreement.
            This Agreement includes any Subscription Documentation (as defined below), as well as any policies
            or exhibits linked to or referenced herein. If you have entered into another agreement with 2 Step
            concerning specific Services, the terms of that agreement control if there is any conflict with
            these terms. Please note that we may modify this Agreement as described in Section 16.8 below.</p>
        <b>A. .DEFINITIONS.</b>
        <p>1. “Add-On” means integrations or applications that are sold or made available by 2 Step, or a
            third-party provider, for authorized use with the Service</p>
        <p>2. “Affiliate(s)” means any entity which is controlled by, in control of, or is under common control
            with a party to this Agreement, where “control” means either the power to direct the management or
            affairs of such entity or ownership of 50% or more of the voting securities of such entity.</p>
        <p>3. “Aggregate/Anonymous Data” means: (i) data generated by aggregating Client Data with other data so
            that results are non-personally identifiable with respect to Client or its Customers and (ii)
            anonymous learnings, logs and data regarding use or effectiveness of the Service.</p>
        <p>4. “Authorized Users” means Client’s employees and contractors who are acting for Client’s benefit
            and on its behalf, and whom Client has authorized to access the Service and the information
            contained therein.</p>
        <p>5. “Business Associate Agreement” (hereinafter “BAA”) means a written agreement between a covered
            entity and a business associate containing the elements specified at 45 CFR 164.504(e).</p>
        <p>6. “Client Data” means: (i) Content and any (ii) Submitted Data.</p>
        <p>7. “Confidential Information” means code, inventions, know-how, product plans, technical and
            financial, business, operational, or other information exchanged under this Agreement or learned
            during the performance of this Agreement, or that is identified as confidential at the time of
            disclosure or should reasonably be considered confidential based on the circumstances surrounding
            the disclosure and the nature of the information disclosed.</p>
        <p>8. “Content” means text, images or other content that Client selects or submits for use or
            incorporation with the Service (including without limitation, chat and message logs).</p>
        <p>9. “Customer(s)” means any person that is a client, customer, or patient of Client, or that is a
            potential client, customer or patient of Client.</p>
        <p>10. “Customer Data” means data related to the identity, characteristics and activities of Customers,
            collected or submitted to the Service(s) by Client or captured by the Service. To the extent Client
            is a Covered Entity or a Business Associate, as those terms are defined in HIPAA, “Client Data” also
            includes PHI.</p>
        <p>11. “Excused Downtime” means unavailability caused by (i) actions or omissions of Client; (ii)
            failures, errors or defects in the facilities, hardware, software or network of Client; or (iii)
            circumstances that constitute a force majeure event or that are beyond 2 Step’s reasonable control.
        </p>
        <p>12. “HIPAA” means the Health Information Technology for Economic and Clinical Health Act of 2009 (the
            “HITECH Act”), the Administrative Simplification section of the Health Insurance Portability and
            Accountability Act of 1996, as codified at 42 U.S.C. §1320d through d-8, as amended from time to
            time, and the requirements of any regulations promulgated under either the HITECH Act or HIPAA,
            including, without limitation, the federal privacy regulations as contained in 45 C.F.R. Parts 160
            and 164, the federal security standards as contained in 45 C.F.R. Parts 160, 162 and, and the
            federal standards for electronic transactions contained in 45 C.F.R. Parts 160 and, all as may be
            amended from time to time.</p>
        <p>13. “Planned Outages” means any scheduled maintenance.</p>
        <p>14. “Proposal” means the quote or proposal provided to Client by 2 Step, prior to Client receiving
            the Service, in which the basic Subscription terms are outlined for Client and this Agreement is
            referenced.</p>
        <p>15. “Protected Health Information” (hereinafter “PHI”) has the same meaning as that term is defined
            in HIPAA.</p>
        <p>16. “Scope of Use” means the usage limits or other scope of use descriptions for the Service included
            in the applicable Proposal or Subscription Documentation. These include any numerical limits on
            Authorized Users, descriptions of product feature levels and names or numerical limits.</p>
        <p>17. “Service(s)” means the specific proprietary product of 2 Step provided to Client for Client’s use
            during the Subscription Term, as part of Client’s Subscription. The “Service(s)” also includes the
            Website, as defined herein. The “Service” does not include any Third-Party Product.</p>
        <p>18. “Submitted Data” means data uploaded, inputted or otherwise submitted by Client to the Service,
            including any Customer Data, PHI or any Third-Party Content.</p>
        <p>19. “Subscription” means Client’s acceptance of the Subscription Documentation provided to Client by
            2 Step, whereby Client agreed to receive the Service on an ongoing basis, according to the terms and
            conditions referenced in the Subscription Documentation. Where applicable, “Subscription” also means
            Beta Releases and/or Free Access Subscriptions, as defined below.</p>
        <p>20. “Subscription Documentation” means any and all documentation evidencing Client’s agreeing to
            enter into a Subscription for the Service(s), including but not limited to a Proposal, Quote,
            Services Agreement, Order Form or Statement of Work, which references this Agreement.</p>
        <p>21. “Subscription Term” means the Initial Term for the Subscription to the applicable Service, as
            specified on Client’s Subscription Documentation, and each subsequent Renewal Term (if any), as
            defined in Sections 8.1 and 8.2, respectively.</p>
        <p>22. “Third-Party Content” means content, data or other materials that Client provides to the 2 Step
            Service(s) from its third-party data providers, including through Add-Ons used by Client.</p>
        <p>23. “Third-Party Product” means any Add-On, applications, integrations, software, code, online
            services, systems and other products not developed by 2 Step.</p>
        <p>24. “2 Step Technology” means the Service(s), any and all related or underlying documentation,
            technology, code, know-how, logos and templates (including in any reports or output obtained from
            the Service), anything delivered as part of support or other services, and any updates,
            modifications or derivative works of any of the foregoing, including as may incorporate any
            Feedback, as defined in Section 10.2 below.
            Other terms are defined in other Sections of this Agreement.</p>
        <b>B. SERVICES.</b>
        <p>1. Services. Subject to Client’s performance of its duties and obligations under this Agreement,
            including but not limited to timely payment of all Fees (as defined herein), 2 Step shall provide
            Client with the Service(s) as specifically set forth in Client’s Subscription Documentation. 2 Step
            shall have no obligation to provide any services or perform any tasks not specifically set forth in
            this Agreement, including any applicable Subscription Documentation.</p>
        <p>2. Alteration of Services. Client and 2 Step may modify the Service(s) set forth in Client’s
            Subscription Documentation to include or remove any Service(s), provided that any material change to
            the Service(s) is agreed to in writing. 2 Step shall have no obligation to perform any Service(s)
            until the parties have agreed up the effect of such change in the applicable Fees.</p>
        <b>C. USE RIGHTS;RESTRICTIONS.</b>
        <p>1. Use of Services. Subject to all terms and conditions of this Agreement, 2 Step grants Client a
            worldwide, non-exclusive, non-transferable, non-sublicensable right and license during the
            applicable Subscription Term to access and use the Service(s) designated on Client’s Subscription
            Documentation solely for Client’s internal business purposes, but only in accordance with this
            Agreement, the applicable Subscription Documentation, and all applicable Scope of Use descriptions.
        </p>
        <p>2. Account Registration. Client will register for a 2 Step account in order to access or receive the
            Service. Account information must be accurate, current and complete, and will be governed by 2
            Step’s Privacy Policy (as provided below in ARTICLE Q) as may be amended from time to time. Client
            agrees to keep this information up-to-date so that 2 Step may send notices, statements and other
            information by email or through Client’s account. By using or accessing the Website, Client agrees
            and consents to 2 Step’s use of cookies in accordance with the terms of 2 Step’s Privacy Policy.</p>
        <p>3. Eligibility and Use by Others. Client and/or Client’s Authorized Users must be over 18 years old
            to use the Service. Client may permit its Authorized Users to use the Service(s) provided their use
            is for Client’s benefit only and remains in compliance with this Agreement. Authorized Users shall
            be subject to the applicable terms and conditions of this Agreement which may be communicated by
            posting to the Website or on a click-through basis to Authorized Users upon access to the Service
            and/or Website (“Terms of Service”). 2 Step reserves the right to change the Terms of Service at any
            time and for any reason in accordance with Section 16.8 below</p>
        <p>4. Responsibility for Authorized Users. Client will be responsible and liable for all Authorized
            Users’ use and access and their compliance with the terms and conditions herein. Client will be
            solely responsible for authorizing and creating user IDs, passwords and other access credentials for
            Authorized Users. Client is solely responsible for determining its Authorized Users and/or
            restricting and/or terminating the rights of such users during the Subscription Term, as Client
            deems appropriate. Client is solely responsible for ensuring that any user IDs, passwords and other
            access credentials (such as API tokens) for the Service are kept strictly confidential and not
            shared with any unauthorized person. Additionally, Client is solely responsible for complying with
            all laws applicable to Client in determining which of its agents or employees should be an
            Authorized User, the level of access for each Authorized User, and when such access should be
            terminated or restricted. Client will be solely responsible for any and all actions taken using its
            and its users’ accounts, passwords or access credentials. Client must notify 2 Step immediately of
            any breach of security or unauthorized use of its account. Use by all Authorized Users in aggregate
            will count towards any applicable Scope of Use restrictions.</p>
        <p>5. General Restrictions. Client must not (and must not allow any third party to): (i) rent, lease,
            copy, transfer, sublicense or provide access to the Service to a third party (except Authorized
            Users as specifically authorized above); (ii) incorporate the Service (or any portion thereof) into,
            or use it with or to provide, any site, product or service; (iii) use the Service (or any portion
            thereof) for time-sharing purposes or for a third party’s benefit; (iv) publicly disseminate
            information regarding the performance of the Service (which is deemed 2 Step’s Confidential
            Information); (v) modify or create a derivative work of the Service or any portion thereof; (vi)
            reverse engineer, disassemble, decompile, translate or otherwise seek to obtain or derive the source
            code, underlying ideas, algorithms, file formats or non-public APIs to any 2 Step Technology or
            Service, except to the extent expressly permitted by applicable law and then only upon advance
            notice to 2 Step; (vii) break or circumvent any security measures or rate limits for Services;
            (viii) distribute any portion of the Service other than as specifically permitted above; or (ix)
            remove or obscure any proprietary or other notices contained in the Service including in any reports
            or output obtained from the Service.</p>
        <p>6. Beta Releases and Free Access Subscriptions. 2 Step may, in its sole discretion, provide Client
            with certain Service(s) for free or on a trial basis (a “Free Access Subscription”) or with “alpha”,
            “beta” or other early-stage Service(s), integrations or features (“Beta Releases”). This Section
            will apply to any Free Access Subscription or Beta Release (even if a Beta Release is provided for a
            fee or counts towards Client’s Scope of Use allocations) and supersedes any contrary provision in
            this Agreement. 2 Step may use good faith efforts in its discretion to assist Client with Free
            Access Subscriptions or Beta Releases. Nevertheless, and without limiting the other disclaimers and
            limitations in this Agreement, CLIENT AGREES THAT ANY FREE ACCESS SUBSCRIPTION OR BETA RELEASE IS
            PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY WARRANTY, SUPPORT, MAINTENANCE, STORAGE,
            SLA OR INDEMNITY OBLIGATIONS OF ANY KIND. WITH RESPECT TO BETA RELEASES, CLIENT FURTHER ACKNOWLEDGES
            AND AGREES THAT BETA RELEASES MAY NOT BE COMPLETE OR FULLY FUNCTIONAL AND MAY CONTAIN BUGS, ERRORS,
            OMISSIONS AND OTHER PROBLEMS FOR WHICH 2 STEP WILL NOT BE RESPONSIBLE. ACCORDINGLY, ANY USE OF A
            BETA RELEASE IS AT CLIENT’S SOLE RISK. 2 Step makes no promises that future versions of a Beta
            Release will be released or will be available under the same commercial or other terms. 2 Step may
            terminate Client’s right to use any Free Access Subscription or Beta Release at any time for any
            reason or no reason in 2 Step’s sole discretion, without liability.</p>
        <b>D. CLIENT DATA.</b>
        <p>1. Rights in Client Data. As between the parties, Client retains all right, title and interest
            (including any intellectual property rights) in and to the Client Data. Client hereby grants 2 Step
            a non-exclusive, worldwide, royalty-free right and license to collect, use, copy, store, transmit,
            modify and create derivative works of the Client Data solely to the extent necessary to provide the
            Service and related services to Client and as otherwise provided herein.</p>
        <p>2. Aggregate/Anonymous Data. Client agrees that 2 Step will have the right to generate
            Aggregate/Anonymous Data. Notwithstanding anything to the contrary herein, the parties agree that
            Aggregate/Anonymous Data is 2 Step Technology, which 2 Step may use for any business purpose during
            or after the term of this Agreement (including without limitation to develop and improve 2 Step’s
            products and services and to create and distribute reports and other materials). 2 Step will not
            distribute Aggregate/Anonymous Data in a manner that personally identifies Client or its Customers,
            or that would otherwise violate applicable laws, such as HIPAA. If Client and 2 Step have entered
            into a BAA, 2 Step will use the Aggregate/Anonymous Data only in conformity with the terms of such
            agreement.</p>
        <p>3. Monitoring. Client understands and agrees that 2 Step, and any third-party platform(s) Client uses
            or accesses in connection with the Service(s), may monitor and analyze Content submitted by Client
            (including but not limited to reviews, surveys, messages, chats, etc.) to improve the Website,
            Services or third-party platform; to improve Client’s experience using the Website, Services or
            third-party platform; to customize and communicate informational or product offerings and promotions
            to Client; and/or to make the Website, Services, or third-party Platform more helpful or useful to
            Client and other users.</p>
        <p>4. Security. 2 Step agrees to maintain physical, technical and organizational measures designed to
            secure its systems from unauthorized access, use or disclosure. If Client is a Covered Entity or
            Business Associate, as defined in HIPAA, 2 Step and Client agree to the terms of 2 Step’s Standard
            Business Associate Agreement (currently attached hereto as Exhibit A), which may be amended from
            time to time, and to secure and utilize PHI in accordance with the BAA. 2 Step takes no
            responsibility and assumes no liability for any Client Data other than its express security
            obligations in this Section.</p>
        <p>5. Storage. 2 Step does not provide an archiving service. During the Subscription Term, Client
            acknowledges that 2 Step may delete Content or Customer Data no longer in active use. Except for
            requirements that are included in any BAA entered into between 2 Step and Client, 2 Step expressly
            disclaims all other obligations with respect to storage.</p>
        <b>E. CLIENT OBLIGATIONS.</b>
        <p>1. Warranty. Client warrants and represents that it is in full compliance with all applicable state
            and federal laws, rules and regulations. In particular, and without limitation, Client warrants and
            represents that it will not use the Service in a manner that would violate any obligation with
            respect to the Federal Trade Commission’s Telemarketing Sales Rule, the Telephone Consumer
            Protection Act of 1991, the Health Insurance Portability and Accountability Act of 1996, the
            Gramm-Leach-Bliley Act of 1999 and any other applicable federal, state, or international law and
            amendments thereto. Client also warrants and represents that (a) Client has sole ownership of any
            Content it provides to 2 Step, that such Content is free of any intellectual property claims by any
            third party, and that 2 Step shall have no obligation to provide Client or third parties any
            compensation for such Content; (b) any Content that Client and/or Client’s authorized
            representative(s) submit for publication on an online review or ratings website as a provider of
            goods or services will be true and accurate, is the original work of Client‘s or their (as
            applicable) authorship, and will only concern Client or the goods and/or services that Client
            provides; (c) Client is authorized to provide 2 Step with any Customer, Client or Authorized User
            information it provides in connection with the Service(s), including any personally identifying
            information; (d) 2 Step’s possession and/or use of the Submitted Data on Client’s behalf in
            connection with the Service will not violate any contract, statute, or regulation; (e) Client and/or
            Client’s authorized representative(s) will only use the Service for interaction with actual
            Customers. Additionally, if an integration is included in the Service Client orders, Client grants 2
            Step the right to access Client’s Information or CRM system directly or through a third-party
            service for the purposes of fulfilling 2 Step’s obligations under this Agreement, and Client
            warrants that Client is not restricted by law or applicable agreement from granting 2 Step such
            right. 2 Step will not be held liable for any consequences of false and/or inaccurate content
            published to an online review or ratings website through 2 Step by Client or its Authorized Users.
        </p>
        <p>2. Customer Consent. Client agrees to: (i) provide all required disclosures to Customers and obtain
            all required consents and/or authorizations from Customers, based on applicable laws, prior to
            utilizing the Service; and (ii) obtain all necessary rights, releases and consents to allow Client
            Data to be collected, used and disclosed in the manner contemplated by this Agreement and to grant 2
            Step the rights herein. Client represents and warrants that its Client Data will not violate
            third-party rights, including intellectual property, privacy and publicity rights. If Client
            receives any take-down requests or infringement notices related to Client Data or its use of
            Third-Party Products, it must promptly stop using these items with the Service and notify 2 Step.
        </p>
        <p>3. Intended Use of the Service. Client understands and agrees that the Service is intended to allow
            Client to send transactional and/or informational messages to Client’s own current Customers only.
            Should Client elect to use the Services to send any messages that constitute advertising or
            telemarketing, or to communicate with anyone other than Client’s current Customers, Client will only
            do so in compliance with all local, state, national and international laws, regulations and
            industry-specific best practices, including but not limited to Do Not Call rules and prohibitions.
        </p>
        <b>F. AVAILABILITY OF SERVICES; SUPPORT.</b>
        <p>1. Availability. Subject to the terms of this Agreement and any Planned Outages and Excused Downtime,
            the Service will be available for access via the Website 99.0% of the time during of the term of
            this Agreement.</p>
        <p>2. Support. 2 Step makes available web-based support through the Website. Additional support services
            may be available to Client upon payment of applicable fees (if any), as specified in any applicable
            Subscription Documentation. Any support services are subject to this Agreement and 2 Step’s
            applicable support policies. 2 Step may also provide onboarding, deployment and other services under
            this Agreement. The scope, pricing and other terms for these additional services will be set forth
            in the applicable Subscription Documentation. 2 Step’s ability to deliver the Service(s) will depend
            on Client’s reasonable and timely cooperation and the accuracy and completeness of any information
            from Client needed to deliver the Service.</p>
        <b>G. FEES AND PAYMENT.</b>
        <p>1. Set-Up Fee. In consideration for the Service(s) to be rendered by 2 Step, Client shall pay to 2
            Step, upon execution of this Agreement, a non-refundable set-up fee in the amount set forth in the
            applicable Subscription Documentation (“Set-Up Fee”).</p>
        <p>2. Fees. In addition to the Set-Up Fee, Client agrees to pay to 2 Step monthly fees as set forth in
            the applicable Subscription Documentation (“Monthly Fee” and collectively with the Set-Up Fees and
            any fees set forth in the applicable Subscription Documentation, the “Fees”). If additional
            functionality is added to the Service(s), the Parties agree to negotiate in good faith for an
            increase in the Fees to become effective at the time of such addition.</p>
        <p>3. Payment of Fees. Unless otherwise specified in the applicable Subscription Documentation, all Fees
            shall be paid monthly, in advance, on the date of your enrollment and every thirty (30) days
            therafter. Payments are non-refundable and non-creditable and payment obligations non-cancellable.
            If Client has elected to pay via credit card, ACH, direct debit, etc., Client authorizes 2 Step to
            charge Fees and other amounts automatically, on an auto-renew basis. If the applicable Subscription
            Documentation allows Client
            to pay in a form not referenced above, payment is due within thirty (30) days of the invoice date,
            unless a different currency or payment period is specified in the applicable Subscription
            Documentation.</p>
        <p>4. Taxes. 2 Step’s Fees are exclusive of all taxes, and Client must pay any applicable taxes or
            levies, whether domestic or foreign, other than taxes based on the income of 2 Step. Client will
            make tax payments to 2 Step to the extent amounts are included in 2 Step’s invoices. Should 2 Step
            be unable to process/receive the Fees when due and owing, payment shall be considered overdue. 2
            Step shall have the right to charge interest on all overdue amounts at the annual rate of 12%,
            compounded monthly, or the maximum lawful amounts, whichever is less. If 2 Step sends the account
            for collection and/or initiates legal action to collect overdue amounts, Client shall be liable for
            all cost and expenses of such action, including reasonable attorney’s fees, court costs, and
            expenses. Additionally, after payment becomes overdue, 2 Step shall have the right to immediately
            suspend Client’s access to the Service.</p>
        <p>5. Annual Fee Increase. Upon sixty (60) days’ prior written notice from 2 Step to Client, Fees for
            the specific Service(s) included in the applicable Subscription Documentation may be adjusted once
            annually by up to 3%, effective on the start date of each Renewal Term.</p>
        <b>H. TERM AND TERMINATION.</b>
        <p>1. Term. This Agreement is effective until the applicable Subscription Term for the Service(s) has
            expired or the Subscription is terminated as expressly permitted herein. The initial Subscription
            Term for any Service is twelve (12) months (“Initial Term”), unless otherwise specified in Client’s
            Subscription Documentation.</p>
        <p>2. Subscription Term and Renewals. By agreeing to any Subscription Documentation, Client is agreeing
            to pay applicable fees for each 30 day period in advance. The Subscription will automatically renew
            to subsequent thirty (30) day periods (“Renewal Term(s)”) unless: (i) otherwise stated on the
            applicable Subscription Documentation or (ii) either party gives written notice of non-renewal at
            least thirty (30) days before the end of the then-current Subscription Term. If no Subscription
            start date is specified on the applicable Subscription Documentation, the Subscription starts when
            Client first obtains access to the Service.</p>
        <p>3. Suspension of Service. 2 Step may suspend Client’s access to the Service(s) if: (i) Client’s
            account is overdue or (ii) Client has exceeded its Scope of Use limits. 2 Step may also suspend
            Client’s access to the Service, remove Client Data or disable Third-Party Products if it determines
            that: (a) Client has breached Sections 3 (Use Rights; Restrictions) or 5 (Client Obligations) or (b)
            suspension is necessary to prevent harm or liability to other Clients or third parties or to
            preserve the security, stability, availability or integrity of the Service. 2 Step will have no
            liability for taking action as permitted above. For avoidance of doubt, Client will remain
            responsible for payment of Fees during any suspension period. However, unless this Agreement has
            been terminated, 2 Step will cooperate with Client to restore access to the Service once it verifies
            that Client has resolved the condition requiring suspension.</p>
        <p>4. Termination for Cause. Either party may terminate this Agreement, including any related
            Subscription Documentation, if the other party: (i) fails to cure any material breach of this
            Agreement (including a failure to pay fees) within thirty (30) days after written notice detailing
            the breach; (ii) ceases operation without a successor; or (iii) seeks protection under any
            bankruptcy, receivership, trust deed, creditors’ arrangement, composition or comparable proceeding,
            or if any such proceeding is instituted against that party (and not dismissed within sixty (60) days
            thereafter). If Client terminates during the Subscription Term for any reason other than the
            foregoing, Client will be responsible for the fees due for the entire Subscription Term. 2 Step may
            also terminate this Agreement or any related Subscription Documentation immediately if Client
            breaches Sections 3 (Use Rights; Restrictions) or 5 (Client Obligations), for repeated violations of
            other Sections of this Agreement, or if applicable, a breach of the parties’ BAA.</p>
        <p>5. Effect of Termination. Upon any expiration or termination of this Agreement or any Subscription
            Documentation: (i) Client’s license rights shall terminate and it must immediately cease use of the
            Service (including any related 2 Step Technology) and delete (or, at 2 Step’s request, return) any
            and all copies of any 2 Step documentation, scripts, passwords or access codes and any other 2 Step
            Confidential Information in Client’s possession, custody or control and (ii) Client’s right to
            access any Client Data in the applicable Service will cease and unless otherwise precluded by a BAA,
            2 Step may delete any such data in its possession at any time. If 2 Step terminates this Agreement
            for cause as provided in Section 8.4 (Termination for Cause), any payments for the remaining portion
            of the Subscription Term will become due and must be paid immediately by Client. Except where this
            Agreement specifies an exclusive remedy, all remedies under this Agreement, including termination or
            suspension, are cumulative and not exclusive of any other rights or remedies that may be available
            to a party.</p>
        <p>6. Survival. The following Sections survive any expiration or termination of this Agreement: 1
            (Definitions); 3 (Use Rights; Restrictions); 4 (Client Data); 7 (Fees and Payment); 8 (Term and
            Termination); 9 (Confidential Information); 10 (2 Step Technology); 11 (Third-Party Products and
            Integrations); 12 (Indemnification); 13 (Disclaimers); 14 (Limitations of Liability); and 16
            (General).</p>
        <b>I. CONFIDENTIAL INFORMATION.</b>
        <p>1. Obligation of Confidentiality. Each party (as the receiving party) must: (i) hold in confidence
            and not disclose the other party’s Confidential Information to third parties except as permitted by
            this Agreement; and (ii) use the other party’s Confidential Information only to fulfill its
            obligations and exercise its rights under this Agreement. Each party may share the other party’s
            Confidential Information with its employees, agents, contractors or subcontractors having a
            legitimate need to know (which, for 2 Step, includes the subcontractors referenced in Section 16.4),
            provided that such party remains responsible for any recipient’s compliance with the terms of this
            Section 10 and these recipients are bound to confidentiality obligations no less protective than
            this Section.</p>
        <p>2 . Exclusions. These confidentiality obligations do not apply to (and Confidential Information does
            not include) information that: (i) is or becomes public knowledge through no fault of the receiving
            party; (ii) was known by the receiving party prior to receipt of the Confidential Information; (iii)
            is rightfully obtained by the receiving party from a third party without breach of any
            confidentiality obligation; or (iv) is independently developed by the receiving party without using
            the disclosing party’s Confidential Information. A party may also disclose the other party’s
            Confidential Information to the extent required by law or court order, provided it gives advance
            notice (if permitted by law) and cooperates in any effort by the other party to obtain confidential
            treatment for the information.</p>
        <p>3. Remedies. The parties acknowledge that disclosure of Confidential Information may cause
            substantial harm for which damages alone may be an insufficient remedy, and so upon breach of this
            Section each party is entitled to seek appropriate equitable relief in addition to any other
            remedies it may have at law.</p>
        <b>J. 2 STEP TECHNOLOGY.</b>
        <p>1. Ownership and Updates. By accepting this Agreement, Client acknowledges that it is obtaining only
            a limited right to use the Service and that irrespective of any use of the words “purchase”, “sale”
            or similar terms, no ownership rights are transferred to Client under this Agreement. Client agrees
            that 2 Step (or its suppliers) retains all rights, title and interest (including all intellectual
            property rights) in and to all 2 Step Technology (which is deemed 2 Step’s Confidential Information)
            and reserves any licenses not specifically granted herein. The Service is offered as an on-line,
            hosted product. Accordingly, Client acknowledges and agrees that it has no right to obtain a copy of
            the software behind any Service and that 2 Step at its option may make updates, bug fixes,
            modifications or improvements to the Service from time- to-time.</p>
        <p>2. Feedback. If Client elects to provide any suggestions, comments, improvements, information, ideas
            or other feedback or related materials to 2 Step (collectively, “Feedback”), Client hereby grants 2
            Step a worldwide, perpetual, non-revocable, sublicensable, royalty-free right and license to use,
            copy, disclose, license, distribute and exploit any such Feedback in any manner without any
            obligation, payment or restriction based on intellectual property rights or otherwise. Nothing in
            this Agreement limits 2 Step’s right to independently use, develop, evaluate or market products,
            whether incorporating Feedback or otherwise.</p>
        <b>K. THIRD-PARTY PRODUCTS AND INTEGRATIONS.</b>
        <p>2 Step may make arrangements with third-party providers, or facilitate Client making arrangements
            with third- party providers, that provide products or services in connection with the Services
            described in this Agreement. If Client uses Third-Party Products in connection with the Service(s),
            those products may make Third-Party Content available to Client and may access Client’s instance of
            the Service, including Client Data. 2 Step does not warrant or support Third-Party Products or
            Third-Party Content (whether or not these items are designated by 2 Step as “powered”, “verified” or
            otherwise) and disclaims all responsibility and liability for these items and their access to the
            Service(s), including their modification, deletion, disclosure or collection of Client Data. 2 Step
            is not responsible in any way for Client Data once it is transmitted, copied or removed from the
            Service(s).</p>
        <b>L. INDEMNIFICATION.</b>
        <p>Client will indemnify and hold 2 Step harmless from and against any third-party claims and related
            costs, damages, liabilities and expenses (including reasonable attorney’s fees) arising from or
            pertaining to (i) any</p>
        <p>Client Data or Content, (ii) 2 Step’s use, as contemplated in this Agreement, of any information
            provided to 2 Step by you or your customers, (iii) breach or alleged breach of this Agreement,
            including Client’s Warranties and Obligations, (iv) any claim of a governmental entity or other
            party that you have violated any law, rule, or regulation, or, (v) if applicable, alleged breach or
            breach of Client’s obligations contained in the BAA. Client also agrees to defend 2 Step against
            these claims at 2 Step’s request, but 2 Step may participate in any claim through counsel of its own
            choosing and the parties will reasonably cooperate on any defense. Client must not settle any claim
            without 2 Step’s prior written consent if the settlement does not fully release 2 Step from
            liability or would require 2 Step to admit fault, pay any amounts or take or refrain from taking any
            action.</p>
        <b>M. DISCLAIMERS.</b>
        <p>EXCEPT AS PROVIDED EXPRESSLY HEREIN, ALL 2 STEP TECHNOLOGY AND RELATED SERVICES ARE PROVIDED “AS IS”
            AND ON AN “AS AVAILABLE” BASIS. NEITHER 2 STEP NOR ITS SUPPLIERS MAKE ANY WARRANTIES, EXPRESS OR
            IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, TITLE,
            FITNESS FOR A PARTICULAR PURPOSE OR NONINFRINGEMENT. 2 STEP MAKES NO REPRESENTATION, WARRANTY OR
            GUARANTEE THAT 2 STEP TECHNOLOGY WILL MEET CLIENT’S REQUIREMENTS OR EXPECTATIONS, THAT CLIENT DATA
            WILL BE ACCURATE, COMPLETE OR PRESERVED WITHOUT LOSS, OR THAT 2 STEP TECHNOLOGY WILL BE TIMELY,
            UNINTERRUPTED OR ERROR-FREE. 2 STEP WILL NOT BE RESPONSIBLE OR LIABLE IN ANY MANNER FOR ANY CLIENT
            PROPERTIES, THIRD-PARTY PRODUCTS, THIRD-PARTY CONTENT, OR NON-2 STEP SERVICES (INCLUDING FOR ANY
            DELAYS, INTERRUPTIONS, TRANSMISSION ERRORS, SECURITY FAILURES, AND OTHER PROBLEMS CAUSED BY THESE
            ITEMS), , FOR THE COLLECTION, USE AND DISCLOSURE OF CLIENT DATA AUTHORIZED BY THIS AGREEMENT, OR FOR
            DECISIONS OR ACTIONS TAKEN (OR NOT TAKEN) BY CLIENT BASED UPON 2 STEP TECHNOLOGY OR 2 STEP’S RELATED
            SERVICES (INCLUDING CHANGES TO CLIENT PROPERTIES). THE DISCLAIMERS IN THIS SECTION SHALL APPLY TO
            THE MAXIMUM EXTENT NOT PROHIBITED BY APPLICABLE LAW, NOTWITHSTANDING ANYTHING TO THE CONTRARY
            HEREIN. CLIENT MAY HAVE OTHER STATUTORY RIGHTS. HOWEVER, ANY STATUTORILY REQUIRED WARRANTIES UNDER
            APPLICABLE LAW, IF ANY, SHALL BE LIMITED TO THE SHORTEST PERIOD AND MAXIMUM EXTENT PERMITTED BY LAW.
        </p>
        <b>N. LIMITATIONS OF LIABILITY.</b>
        <p>TO THE MAXIMUM EXTENT NOT PROHIBITED BY APPLICABLE LAW, IN NO EVENT WILL 2 STEP OR ITS SUPPLIERS BE
            LIABLE FOR ANY LOSS OF USE, LOST OR INACCURATE DATA, INTERRUPTION OF BUSINESS, LOST PROFITS, COSTS
            OF DELAY, REPUTATIONAL HARM, OR ANY INDIRECT, SPECIAL, INCIDENTAL, COVER, RELIANCE OR CONSEQUENTIAL
            DAMAGES OF ANY KIND, HOWEVER CAUSED, EVEN IF INFORMED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES.
            TO THE MAXIMUM EXTENT NOT PROHIBITED BY APPLICABLE LAW, IN NO EVENT WILL 2 STEP OR ITS SUPPLIERS BE
            LIABLE FOR ANY NEGATIVE REVIEWS OF CLIENT POSTED IN THE CONTENT AS PART OF THE SERVICE, INCLUDING,
            WITHOUT LIMITATION, ANY CLAIMS BY CLIENT FOR LOSS OF BUSINESS, LOST PROFITS, REPUTATIONAL HARM, OR
            ANY INDIRECT, SPECIAL OR INCIDENTAL, COVER, RELIANCE OR CONSEQUENTIAL DAMAGES OF ANY KIND THAT ARISE
            OR RESULT FROM SUCH NEGATIVE REVIEWS. IN NO EVENT WILL 2 STEP’S OR ITS SUPPLIERS’ TOTAL LIABILITY
            EXCEED IN AGGREGATE FIVE HUNDRED U.S. DOLLARS ($500.00 US). FOR FREE ACCESS SUBSCRIPTIONS OR BETA
            RELEASES PROVIDED WITHOUT CHARGE, 2 STEP’S TOTAL LIABILITY SHALL NOT EXCEED IN AGGREGATE FIFTY U.S.
            DOLLARS ($50.00 US). NOTWITHSTANDING THE FOREGOING, NONE OF THE LIMITATIONS IN THIS SECTION 15
            EXCLUDES EITHER PARTY’S LIABILITY FOR FRAUD OR FOR DEATH OR PERSONAL INJURY TO THE EXTENT CAUSED BY
            A PARTY’S NEGLIGENCE. IN ADDITION, THE LAWS IN SOME JURISDICTIONS MAY NOT ALLOW SOME OF THE
            LIMITATIONS OF LIABILITY IN THIS SECTION. IF ANY OF THESE LAWS IS FOUND TO APPLY TO THIS AGREEMENT,
            THIS SECTION 14 SHALL APPLY TO THE MAXIMUM EXTENT NOT PROHIBITED BY SUCH LAW. EACH PARTY
            ACKNOWLEDGES AND AGREES THAT THIS SECTION 14 IS A FUNDAMENTAL BASIS OF THE BARGAIN AND A REASONABLE
            ALLOCATION OF RISK BETWEEN THE PARTIES AND WILL SURVIVE AND APPLY TO ANY CLAIMS ARISING OUT OF OR
            RELATED TO THIS AGREEMENT, ANY 2 STEP TECHNOLOGY OR ANY RELATED SERVICES, REGARDLESS OF THE THEORY
            OF LIABILITY (CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE), EVEN IF ANY LIMITED REMEDY IN THIS
            AGREEMENT IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.</p>
        <b>O. DISPUTE RESOLUTION.</b>
        <p>If a dispute arises between the parties related to this Agreement or the Services provided therefrom,
            and the dispute cannot be settled through informal negotiations, the parties agree to resolve their
            dispute (referred to herein as “Claim(s)”) as follows:</p>
        <p>1. Mediation. The Parties agree to participate in mediation to settle their Claims in accordance with
            the rules and procedures found in Utah Code § 78B-10-101 et seq. (Utah Uniform Mediation Act and
            referred to as “UUMA”) and this Agreement before a party can file a judicial action, whether in a
            court of law, an administrative body, government agency, or otherwise. If there are any conflicting
            provisions between UUMA and this Agreement, the provisions in this Agreement shall govern.</p>
        <p>2. Notice. Mediation shall be conducted within sixty (60) days from a party receiving written notice
            of Claims from a complaining party. The notice must contain a detailed description of the nature of
            the Claims and the requested relief sought.</p>
        <p>3. Mediator Selection and Mediator Fees. A neutral mediator shall be selected as mutually agreed upon
            by the parties. The mediator’s fees and costs shall be paid to the mediator at the end of mediation,
            with both parties equally sharing the mediation costs and paying their own legal fees and costs.</p>
        <p>4. Location. Mediation shall occur in Nassau County, New York.</p>
        <p>5. Judicial Action. If the parties are unable to resolve the Claim pursuant to the mandatory
            mediation referenced above (or if one of the parties refuses to participate in the mandatory
            mediation or fails to respond to a complaining party’s request for mediation), the parties may
            subsequently file a judicial action.</p>
        <p>6. Disputes Not Subject to the Mediation Process. The following claims are not subject to the
            mandatory mediation provisions of this Section 15:1.An order of injunctive relief and any related
            incidental damages; and/or an order to prevent the disclosure of or misuse of Confidential
            Information or Trade Secrets.</p>
        <b>P . GENERAL.</b>
        <p>1. Assignment. This Agreement will bind and inure to the benefit of each party’s permitted successors
            and assigns. Neither party may assign this Agreement without the advance written consent of the
            other party, except that 2 Step may assign this Agreement without consent to an Affiliate or in
            connection with a merger, reorganization, acquisition or other transfer of all or substantially all
            of its assets or voting securities. Any attempt to transfer or assign this Agreement except as
            expressly authorized under this Section 16.1 will be null and void.</p>
        <p>2. Notices. Any notice or communication to 2 Step under this Agreement must be in writing. Client
            must send any notices under this Agreement (including breach notices) to 2 Step Headquarters and
            include “Attn. Legal Department” in the subject line. 2 Step may send notices to the e-mail
            addresses on Client’s account or, at 2 Step’s option, to Client’s last-known postal address. 2 Step
            may also provide operational notices regarding the Service or other business-related notices through
            conspicuous posting of such notice on 2 Step’s website or the Service. Each party hereby consents to
            receipt of electronic notices. 2 Step is not responsible for any automatic filtering Client or its
            network provider may apply to email notifications.</p>
        <p>3. Publicity. Unless otherwise specified in the applicable Subscription Documentation, 2 Step may use
            Client’s name, logo and marks (including marks on Client Properties) to identify Client as a 2 Step
            Client on 2 Step’s website and other marketing materials.</p>
        <p>4. Subcontractors. 2 Step may use subcontractors and permit them to exercise the rights granted to 2
            Step in order to provide the Service and related services under this Agreement.</p>
        <p>5. Subpoenas. Nothing in this Agreement prevents 2 Step from disclosing Client Data to the extent
            required by law, subpoenas, or court orders, but 2 Step will use commercially reasonable efforts to
            notify Client where permitted to do so.</p>
        <p>6. Independent Contractors. The parties to this Agreement are independent contractors, and this
            Agreement does not create a partnership, joint venture, employment, franchise, or agency
            relationship. Neither party has the power to bind the other or incur obligations on the other
            party’s behalf without the other party’s prior written consent.</p>
        <p>7. Force Majeure. Neither party will be liable for any delay or failure to perform its obligations
            under this Agreement (except payment obligations) if the delay or failure is due to causes beyond
            its reasonable control, such as a strike, blockade, war, act of terrorism, riot, natural disaster,
            failure or reduction of power or telecommunications or data network or services, or government act.
        </p>
        <p>8. Amendments; Waivers. 2 Step may update or modify these Terms (including referenced policies and
            other documents) from time to time by posting a revised version on the Website or Service or by
            notification via the email associated with your account. You may also be required to click through
            the updated Terms to show acceptance. In any event, subject to the terms of your specific
            Subscription Documentation, the modified terms will become effective upon posting or notification
            and continued use of the Service or Website, following the update, shall constitute acceptance of
            the updated Agreement. If Client does not agree to the updated Agreement after it takes effect,
            Client will no longer have the right to use the Service. Except as otherwise described in this
            Section, any modification or amendment to this Agreement must be made in writing and signed by a
            duly authorized representative of each party (each in its discretion). No waiver will be implied
            from conduct or failure to enforce or exercise rights under this Agreement. No waiver of any
            provision of this Agreement shall constitute a waiver of any other provision, whether or not
            similar, nor shall any waiver constitute a continuing waiver. Failure to enforce any provision of
            this Agreement shall not operate as a waiver of such provision or any other provision or of the
            right to enforce such provision or any other provision. Waivers must be made in writing and executed
            by a duly authorized representative of the waiving party.</p>
        <p>9. Headings. The headings used in this Agreement are for ease of reference only. They are not
            intended as a complete re-statement of the matters contained under each heading, and you acknowledge
            that you have read and understand all the text of this Agreement, and not just the headings.</p>
        <p>10. Legal Disclaimer. 2 Step does not provide its Clients with legal advice regarding compliance,
            data privacy or other relevant law in the jurisdiction in which its Clients use the Service, and any
            statements made by 2 Step to its Client(s) shall not constitute legal advice.</p>
        <p>11. Severability. If any provision of this Agreement is found by any court of competent jurisdiction
            to be unenforceable or invalid, that provision will be limited to the minimum extent necessary so
            that this Agreement may otherwise remain in effect, and all other provisions remain in full effect
        </p>
        <p>12. No Third-Party Rights. Nothing in this Agreement confers on any third party the right to enforce
            any provision of this Agreement. Client acknowledges that each Subscription only permits use by and
            for the legal entity or entities identified in the Subscription Documentation and not any
            Affiliates.</p>
        <p>13. Attorneys’ Fees and Costs. The substantially prevailing party in any action to enforce this
            Agreement will be entitled to recover its reasonable attorneys’ fees and costs for the action.</p>
        <p>14. Entire Agreement. This Agreement, including these Terms and any applicable Subscription
            Documentation, represents the parties’ complete and exclusive understanding relating to the
            Agreement’s subject matter. It supersedes all prior or contemporaneous oral or written
            communications, proposals and representations with respect to the 2 Step Technology or any other
            subject matter covered by this Agreement. The terms of the United Nations Convention on Contracts
            for the Sale of Goods do not apply to this Agreement. The Uniform Computer Information Transactions
            Act (UCITA) shall not apply to this Agreement regardless of when or where adopted. Any terms
            provided by Client (including as part of any purchase order or other business form used by Client)
            are for administrative purposes only, and have no legal effect.</p>
        <p>15. Counterparts; Electronic Transmission. This Agreement may be executed in counterparts, each of
            which shall constitute an original, and all of which shall constitute one and the same instrument. A
            facsimile or other reproduction of this Agreement may be executed by one or more Parties hereto, and
            an executed copy of this Agreement may be delivered by one or more Parties hereto by facsimile or
            similar electronic transmission device pursuant to which the signature of or on behalf of such Party
            can be seen, and such execution and delivery shall be considered valid, binding and effective for
            all purposes. At the request of any Party hereto, all Parties hereto agree to execute an original of
            this Agreement as well as any facsimile or other reproduction hereof.</p>
        <p>16. Governing Law; Jurisdiction and Venue. This Agreement is governed by the laws of the State of New
            York, without regard to choice or conflict of law rules thereof. The exclusive jurisdiction and
            venue for actions related to the subject matter of this Agreement shall be the state courts located
            in Nassau County, New York or the United States District Court for the Eastern District of New York,
            and both parties submit to the personal jurisdiction of these courts.</p>
        <b>Q. PRIVACY POLICY.</b>
        <p>By visiting or accessing the Website, or by signing up for any Services or purchasing any products
            (“Products”) from 2 Step, you are agreeing to the following privacy policy (“Privacy Policy”),
            which, together with the Terms and Conditions, govern your use of the Website, the Services, and
            Products. Terms used in this Privacy Policy have the same meaning as those set forth in the Terms
            and Conditions, unless otherwise specified. The term “You” in this Agreement means any person or
            entity who accesses the Website, uses the Services, or purchases any of the Products.</p>
        <p>1. Privacy Policy. This Privacy Policy governs how we use the information you provide to us in
            connection with the Website, the Services, and the Products. Therefore, it is critical that you
            review and understand this Privacy Policy. We may change this Privacy Policy periodically and will
            notify you by posting the updates on the Website. You should review this Privacy Policy
            periodically, as your use of the Website indicates your continuing acceptance of the provisions
            current at the time of your use.</p>
        <p>2. Applicable Information: This Privacy Policy applies to the information you provide us on the
            Website. “Personal Information” consists of your, or your client’s, first and last name, e-mail
            address, physical address, login information, billing information, credit card number, mobile device
            type, ads you click on, and any contact information you provide us. It also includes information
            related to your use of the Website, such as the date and time of your visits, IP address, and other
            information gathered through the use of cookies (see definition of “cookies” below). Personal
            Information does not include information you provide for our public use in connection with the
            Website, such as the information you provide for your personal profile and the items defined in the
            Terms and Conditions as “Received Materials” and “Uploaded Materials.” Those items are, by their
            nature, intended for public use, and they are governed by the license you grant in our Terms and
            Conditions.</p>
        <p>3. Use of Personal Information: We never sell, rent, lease, or make available your Personal
            Information for use by any third party. However, we may internally use your Personal Information to
            improve the Website, to improve your experience as you use the Website, to customize and communicate
            informational or product offerings and promotions to you, and to make our Services more helpful or
            useful to other users. We may use the services of a third party to perform these functions. In
            addition, we may allow ad exchanges, web analytics vendors, social networking platforms and other
            third parties to collect certain data and other non-personally identifiable information when you
            visit our Site. These companies may use non-personally identifiable information (e.g., search and
            click stream information, browser type, time and date, the identity or type of advertisements
            clicked or scrolled over) during your visits to this and other web sites in order to provide
            advertisements about goods and services likely to be of interest to you. By way of example, these
            companies may use a cookie or web beacon to collect this information.</p>
        <p>4. Cookies: A cookie is a very small text document, which often includes an anonymous unique
            identifier. Cookies can help differentiate you from other users and keep you from having to log in
            repeatedly. When you visit a website, that site’s computer asks your computer for permission to
            store this file in a part of your hard drive specifically designated for cookies. Each website can
            send its own cookie to your browser if your browser’s preferences allow it, but (to protect your
            privacy) your browser only permits a website to access the cookies it has already sent to you, not
            the cookies sent to you by other sites. Browsers are usually set to accept cookies. However, if you
            would prefer not to receive cookies, you may alter the configuration of your browser to refuse
            cookies. If you choose to have your browser refuse cookies, it is possible that some areas of the
            Website will not function as effectively when viewed by the users. A cookie cannot retrieve any
            other data from your hard drive or pass on computer viruses. You must disable cookies on each
            computer you use and for each browser on each computer.</p>
        <p>5. Unsubscribing: If you wish us to stop sending offers and promotions to you, you may unsubscribe at
            any time by clicking unsubscribe on digital material or contacting support@www.2stepreviews.com.</p>
        <p>6. Cooperation with Authorities, Consent to United States Processing: 2 Step complies with all
            applicable laws and regulations. This may require cooperation with governmental authorities with
            respect to information we receive from you. If we believe it is necessary, in our sole discretion,
            to disclose information about you to law enforcement officials or in connection with a court
            proceeding, you hereby consent to such disclosure. In addition, if we believe a disclosure of such
            information is necessary to protect our property and rights, to protect public safety, or to prevent
            potentially illegal or unethical conduct, we may disclose certain information, and you consent to
            such disclosure. If you reside outside the United States, your local laws may afford a higher degree
            of protection to your Personal Information than provided under United States law. Your use of the
            Website constitutes your consent to have your Personal Information transferred to, collected by, and
            processed by servers in the United States.</p>
        <p>7. Assignment of Rights to Information: In the event of the sale, acquisition, or merger of 2 Step.
            or of all or substantially all of its assets, and in the event of any other change of control of 2
            Step, your Personal Information, Received Materials, and Uploaded Materials, in addition to all
            other information possessed by 2 Step may be transferred, sold, or shared in connection with such
            transaction. In that event, your Personal Information will still be subject to the same protections
            as provided herein.</p>
        <p>8. Child Online Privacy Protection Act: 2 Step complies with the Child Online Privacy Protection Act
            (“COPPA”). Our Terms and Conditions govern our activities and your conduct in relation to COPPA.</p>
        <p>9. California Users: California residents are entitled once a year to request and obtain certain
            information regarding our disclosure, if any, of personal information to third parties for their
            direct marketing purposes during the immediately prior calendar year (e.g. requests made in 2016
            will receive information about 2015 sharing activities). As set forth in this privacy policy, we
            comply with this law by offering our visitors the ability to tell us not to share your personally
            identifiable information with third parties for their direct marketing purposes. To make such a
            request, please send an e-mail to support@www.2stepreviews.com. You must include this Website as the
            subject line, and your full name, e-mail address, and postal address in your message.</p>
        <p>10. Deleting Your Information: If you have registered with us, you may delete the Personal
            Information in your account by contacting us. We will make commercially reasonable efforts to delete
            such information upon request. We may archive all or some of your Personal Information as we believe
            may be required by law or for legitimate business purposes.</p>
        <p>11. Community Features: The Website may provide, at its sole discretion, one or more sharing
            features, review submission features, chat areas, message boards, e-mail functions, messaging
            services, file communities, polls, surveys, and other services (collectively, the “Community
            Features”). We provide these Community Features subject to the Terms of Use, and the community
            guidelines set forth below. Any user who posts content in the Community Features (“Community
            Content”) is responsible for the reliability, accuracy and truthfulness of such Community Content,
            and we have no control over the same. In addition, we have no control over whether any such posted
            material is of a nature that users will find offensive, distasteful or otherwise unacceptable and we
            expressly disclaim any responsibility for such material. By using any of the Community Features, you
            acknowledge and agree that information you post or otherwise make public will be available to the
            public.</p>
        <p>12. Please review our restrictions on use and guidelines for Community Features. In addition, please
            note that this Privacy Policy does not apply to any information you may disclose publicly in any
            Community Feature. You should keep in mind that whenever you publicly disclose information about
            yourself online, that information could be collected and used by people that you do not know. We
            bear no responsibility for any action or policies of any third parties who collect any information
            users may disclose in Community Features or other public areas, if any, of this Website.</p>
        <p>13. In consideration for your use of the Website’s Community Features and functionality, you agree to
            comply with the Community Guidelines set forth below. Users who violate the community guidelines,
            may, in our sole discretion, be permanently banned from using the Website.</p>
        <p>14. Community Guidelines: Entering any Community Feature will constitute acceptance of the Community
            Guidelines. If you do not agree to abide by the Community Guidelines, please do not enter any
            Community Features. You are entirely responsible and liable for all activities conducted by you
            within the Community Features, including the transmission, posting, or other provision of Community
            Content to any portion of the Community Features. listed below are some, though not all, violations
            that may result in
            us terminating or suspending your access to Community Features on a temporary or permanent basis, as
            determined by us in our sole discretion. You agree not to do any of the following actions while
            using any of the Community Features:</p>
        <p>• Violate these Community Guidelines or abuse the community purpose of the chat areas. • Participate
            in any part of the Site if you are under the age of 18.</p>
        <p>• Use profanity, obscenities, or **asterisks** or other “masking” characters to disguise profane or
            obscene words.</p>
        <p>• Use obscene, lewd, slanderous, pornographic, abusive, violent, insulting, indecent, threatening and
            harassing language of any kind.</p>
        <p>• Impersonate other users.</p>
        <p>• Attack (flame or “cyberbully”) individuals, companies or products, although you are otherwise
            welcome to offer your opinions or comments on the subject at hand.</p>
        <p>• Post topics on message boards, on the Site or within any of the Community Features that are
            unrelated to the subject of the Website.</p>
        <p>• Advertise or promote other companies or URL’s.</p>
        <p>• Include advertising in communications.</p>
        <p>• Post material that is copyrighted, protected by trade secret or otherwise subject to third party
            proprietary rights, including privacy and publicity rights, unless you are the owner of such rights
            or have permission from their rightful owner to post the material and to grant us all of the license
            rights granted herein.</p>
        <p>• Include advanced fonts, java, tables, html, or other programming codes or commands in messages.</p>
        <p>• Distribute or otherwise publish any Community Content containing any solicitation of funds,
            promotion, advertising, or solicitation for goods or services. You also hereby acknowledge that you
            are prohibited from soliciting other guests to join or become members of any commercial online
            service or other organization.</p>
        <p>• Collect or harvest the information of any user or otherwise access the Website using automated
            means (including but not limited to harvesting bots, robots, spiders or scrapers).</p>
        <p>15. Safeguarding Your Information. 2 Step values very highly the security of your Personal
            Information. We have implemented reasonable security measures to ensure that security. While no
            system can be guaranteed to be 100% secure all the time, we strive to use the best practices used in
            the community to protect your Personal information. We will make any disclosures that are we
            discover any breach of the security of your Personal information, including notifying you via e-mail
            or conspicuous posting on the Website without unreasonable delay.</p>
        <p>Last revised: January 10, 2019</p>
        <p>EXHIBIT A</p>
        <p>Business Associate Agreement</p>
        <p>2 STEP BUSINESS ASSOCIATE AGREEMENT</p>
        <p>This Business Associate Agreement (“Agreement”) shall be incorporated into the applicable Terms of
            Service or Master Service Agreement for 2 Step Clients or Customers that are Covered Entities and
            provide Protected Health Information (“PHI”) (as defined in HIPAA) to 2 Step Reviews, Inc. in the
            course of using or accessing purchased 2 Step Services.</p>
        <p>The Parties to this Agreement are committed to complying with the Standards for Privacy of
            Individually Identifiable Health Information (the “Privacy Rule”) and the Standards for Security of
            Electronic Protected Health Information (the “Security Rule) under the Health Insurance Portability
            and Accountability Act of 1996 (“HIPAA”). This Agreement, in conjunction with the Privacy and
            Security Rules, sets forth the terms and conditions pursuant to which PHI (electronic and
            non-electronic) that is created, received, maintained, or transmitted by, the Business Associate
            from or on behalf of Covered Entity, will be handled between the Business Associate and Covered
            Entity and with third parties during the term of their Underlying Agreement and after its
            termination.</p>
        <p>1. Definitions.</p>
        <p>1.1. HIPAA Rules. “HIPAA Rules” shall mean the Privacy, Security, Breach Notification, and
            Enforcement Rules at 45 CFR Part 160 and Part 164. A reference in this Agreement to a section in the
            HIPAA Rules means the section as in effect or as amended.</p>
        <p>1.2. Other terms. The following terms used in this Agreement shall have the same meaning as those
            terms in the HIPAA Rules: Business Associate, Breach, Covered Entity, Data Aggregation, Designated
            Record Set, Disclosure, Electronic Protected Health Information, Health Care Operations, Individual,
            Minimum Necessary, Notice of Privacy Practices, Privacy Officer, Privacy Rule, Protected</p>
        <p>Health Information (“PHI”), Required By Law, Secretary, Security Rule, Security Incident,
            Subcontractor, Unsecured Protected Health Information, and Use.</p>
        <p>2. PERMITTED USES AND DISCLOSURES OF PHI</p>
        <p>2.1 Services. Business Associate provides services (“Services”) for Covered Entity that involve the
            receipt, use and disclosure of PHI. Except as otherwise specified herein, the Business Associate may
            make all uses of PHI necessary to perform its obligations under the Underlying Agreement. All other
            uses not authorized by this Agreement are prohibited. Moreover, Business Associate may disclose PHI
            for the purposes authorized by this Agreement only: (i) to its employees, subcontractors and agents,
            in accordance with Section 3.1(d), or (ii) as otherwise permitted by or as required by the Privacy
            or Security Rule.</p>
        <p>2.2 Business Activities of the Business Associate. Unless otherwise limited herein and if such use or
            disclosure of PHI would not violate the Privacy or Security Rules if done by the Covered Entity, the
            Business Associate may:</p>
        <p>a. Use the PHI in its possession for its proper management and administration and to fulfill any
            present or future legal responsibilities of the Business Associate provided that such uses are
            permitted under state and federal confidentiality laws.</p>
        <p>b. Disclose the PHI in its possession to third parties for the purpose of its proper management and
            administration or to fulfill any present or future legal responsibilities of the Business Associate,
            provided that the Business Associate represents to Covered Entity, in writing, that (i) the
            disclosures are required by law, or (ii) the Business Associate has received from the third party
            written assurances regarding its confidential handling of such PHI as required under 45 C.F.R. §
            164.504(e)(4) and § 164.314, and the third party notifies the Business Associate of any instances of
            which it is aware in which the confidentiality of the information has been breached.</p>
        <p>3. RESPONSIBILITIES OF THE PARTIES WITH RESPECT TO PHI</p>
        <p>3.1 Responsibilities of the Business Associate. Business Associate hereby agrees to do the following:
        </p>
        <p>a. Not use or disclose PHI other than as permitted or required by the Agreement or as required by
            law.</p>
        <p>b. Use appropriate safeguards, and comply with Subpart C of 45 CFR Part 164 with respect to
            electronic PHI, to prevent use or disclosure of PHI other than as provided for by the Agreement.</p>
        <p>c. Report in writing to Covered Entity within five (5) business days any use or disclosure of PHI not
            provided for by the Agreement of which it becomes aware, including breaches of unsecured PHI as
            required at 45 CFR 410, and any security incident of which it becomes aware, and cooperate with the
            Covered Entity in any mitigation or breach reporting efforts.</p>
        <p>d. In accordance with 45 CFR 502(e)(1)(ii) and 164.308(b)(2), if applicable, to ensure that any
            subcontractors that create, receive, maintain, or transmit PHI on behalf of the Business Associate
            agree to the same restrictions, conditions, and requirements that apply to the Business Associate
            with respect to such information.</p>
        <p>e. Ensure that any agent or subcontractor to whom the Business Associate provides PHI, as well as
            Business Associate, not export PHI for storage beyond the borders of the United States of America.
        </p>
        <p>f. With respect to any agent or subcontractor who has access to PHI from beyond the borders of the
            United States of America:</p>
        <p>i. Ensure that any such individuals are bound by the terms and conditions of this Agreement or a
            subcontractor Agreement containing substantially similar terms and conditions; and</p>
        <p>ii. Ensure that any such individuals with access to PHI beyond the borders of the United States of
            America are subject to the jurisdiction of the courts in the United States of America; and</p>
        <p>g. Within ten (10) business days request of Covered Entity, make available PHI in a designated record
            set, if applicable, to Covered Entity, as necessary to satisfy Covered Entity’s obligations under 45
            CFR 524.</p>
        <p>h. Within ten (10) business days, make any amendment(s) to PHI, if applicable, in a designated record
            set as directed or agreed to by the Covered Entity pursuant to 45 CFR 526, or take other measures as
            necessary to satisfy Covered Entity’s obligations under 45 CFR 164.526.</p>
        <p>i. As applicable, maintain and make available the information required to provide an accounting of
            disclosures as necessary to satisfy Covered Entity’s obligations under 45 CFR 528.</p>
        <p>j. To the extent the Business Associate is to carry out one or more of Covered Entity’s obligation(s)
            under Subpart E of 45 CFR Part 164, comply with the requirements of Subpart E that apply to the
            Covered Entity in the performance of such</p>
        <p>k. Make its internal practices, books, and records available to the Secretary and to the Covered
            Entity for purposes of determining compliance with the HIPAA</p>
        <p>l. Comply with minimum necessary requirements under the HIPAA Rules.</p>
        <p>3.2 Responsibilities of Covered Entity. Regarding the use and/or disclosure of PHI by the Business
            Associate, Covered Entity hereby agrees:</p>
        <p>a. To inform the Business Associate of any limitations in the form of notice of privacy practices
            that Covered Entity provides to individuals pursuant to 45 C.F.R. §164.520, to the extent that such
            limitation may affect Business Associate’s use or disclosure of PHI.</p>
        <p>b. To inform the Business Associate of any changes in, or revocation of, the permission by an
            individual to use or disclose PHI, to the extent that such limitation may affect Business
            Associate’s use or disclosure of PHI.</p>
        <p>c. To notify the Business Associate, in writing and in a timely manner, of any restriction on the use
            or disclosure of PHI that Covered Entity has agreed to or is required to abide by under 45 CFR
            164.522, to the extent that such restriction may impact in any manner the use and/or disclosure of
            PHI by the Business Associate under this Agreement</p>
        <p>d. .Covered Entity will not request Business Associate to use or disclose PHI in any manner that
            would not be permissible under the Privacy and Security Rule if done by the Covered Entity.</p>
        <p>4. TERMS AND TERMINATION</p>
        <p>4.1. Term. The Term of this Agreement shall commence on the Effective Date, and shall terminate on
            the termination date of the relevant Underlying Agreement or on the date Covered Entity terminates
            this Agreement for cause as authorized in paragraph 4.2 of this Section, whichever is sooner.</p>
        <p>4.2. Termination for Cause. Business Associate authorizes termination of this Agreement by Covered
            Entity, if Covered Entity determines Business Associate has violated a material term of the
            Agreement and Business Associate has not cured the breach or ended the violation within the time
            specified by Covered Entity.</p>
        <p>4.3. Obligations of Business Associate upon Termination. Business Associate agrees to return or
            destroy all PHI pursuant to 45 C.F.R. § 164.504(e) (2) (ii)(J), if it is feasible to do so. If it is
            not feasible for the Business Associate to return or destroy said PHI, the Business Associate will
            notify Covered Entity in writing. Said notification shall include: (i) a statement that the Business
            Associate has determined that it is not feasible to return or destroy the PHI in its possession, and
            (ii) the specific reasons for such determination. Business Associate agrees to extend all
            protections, limitations and restrictions contained in this Agreement to the Business Associate’s
            use and/or disclosure of any PHI retained after the termination of this Agreement, and to limit any
            further uses and/or disclosures to the purposes that make the return or destruction of the PHI
            infeasible.</p>
        <p>4.4. Automatic Termination. This Agreement will automatically terminate without any further action of
            the Parties upon the termination or expiration of the Underlying Agreement.</p>
        <p>5. INDEMNIFICATION</p>
        <p>5.1. Mutual Indemnification. Each Party (each an “Indemnifying Party”) agrees to indemnify and defend
            the other Party (each an “Indemnified Party”) for any costs, fees, fines, settlements, judgments,
            including attorney’s fees and court costs incurred as a result of a material breach of this
            Agreement by the Indemnifying Party or its agents or subcontractors, or as a result of any gross
            negligence or willful misconduct by an Indemnifying Party or its agents or subcontractors.</p>
        <p>6. MISCELLANEOUS</p>
        <p>6.1. Business Associate. For purposes of this Agreement, Business Associate shall include the named
            Business Associate herein. However, if the Business Associate is otherwise a Covered Entity under
            the Privacy or Security Rule, that entity may appropriately designate a health care component of the
            entity, pursuant to 45 C.F.R. § 164.504(a), as the Business Associate for purposes of this
            Agreement.</p>
        <p>6.2. Survival. The respective rights and obligations of Business Associate and Covered Entity under
            this Agreement shall survive termination of this Agreement indefinitely.</p>
        <p>6.3. Amendments; Waiver. This Agreement may not be modified, nor shall any provision hereof be waived
            or amended, except in a writing duly signed by authorized representatives of the Parties. A waiver
            with respect to one event shall not be construed as continuing, or as a bar to or waiver of any
            right or remedy as to subsequent events. The Parties agree to take such action as is necessary to
            amend this Agreement from time to time as is necessary for compliance with the requirements of the
            HIPAA Rules and any other applicable law.</p>
        <p>6.4. Interpretation. Any ambiguity in this Agreement shall be interpreted to permit compliance with
            the HIPAA Rules.</p>
        <p>6.5. No Third-Party Beneficiaries. Nothing express or implied in this Agreement is intended to
            confer, nor shall anything herein confer, upon any person other than the Parties and the respective
            successors or assigns of the Parties, any rights, remedies, obligations, or liabilities whatsoever.
        </p>
        <p>6.6. Notices. Any notices to be given hereunder to a Party shall be made as described in the
            applicable services agreement or via U.S. Mail or express courier to such Party’s address given
            below.</p>
        <p>If to Business Associate, to:</p>
        <p>2 Step Reviews LLC</p>
        <p>____________________</p>
        <p>____________________</p>
        <p>Attn: _______________</p>
        <p>If to Covered Entity, to:</p>
        <p>The current address associated with Client’s 2 Step account, where applicable.</p>
    </div>
</div>