import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { take } from 'rxjs/operators';
import { ToastrService } from "ngx-toastr";
import { FcmService } from "../../fcm/fcm.service";

@Injectable()
export class MessagingService {

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    public toastr: ToastrService,
    public fcmService: FcmService
  ) {
    this.angularFireMessaging.messages.subscribe(
      (_messaging: AngularFireMessaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      });
  }

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token;
        this.angularFireDB.object('fcmTokens/').update(data)
      })
  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.fcmService.updateFCM(token, userId).subscribe((response) => {
          this.updateToken(userId + window.navigator.userAgent.replace(/\D+/g, ''), token);
        }, (error) => {
          console.log(error);
        });
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  deleteToken() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.angularFireMessaging.deleteToken(token).subscribe(
          (token) => {
            console.log(token);
          },
          (err) => {
            console.error(err);
          }
        );
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foregro  und
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        this.toastr.success(payload['notification']['body'], payload['notification']['title']);
      });
  }
}