import "reflect-metadata";
import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from "typedjson";
import { FeatureModel } from "./feature";
import { FeatureType } from "./feature-type";
import { PlanModel } from "./plan.model";

@jsonObject
export class UpgradeAccountModel {

  @jsonMember
  plan: PlanModel;

  @jsonArrayMember(FeatureModel)
  features: FeatureModel[];

  constructor(data) {
    this.plan = new PlanModel(data?.plan);
    this.features = [];
    data?.features?.forEach(feature => {
      this.features.push(new FeatureModel(feature));
    });
  }

  public getStatusFeature(feature: String, totalQuantity?: number, selected?: number) {

    const currentFeature = this.features.find(f => f.name === feature);

    if (currentFeature?.type == FeatureType.Functionality) {
      return (currentFeature.val == 1) ? true : false;
    }

    if (currentFeature?.type == FeatureType.Quota) {

      if (currentFeature.val == -1) {
        return true;
      }
      else {
        if (!selected) {
          return ((totalQuantity) > 0) ? true : false;
        }
        else {
          return ((totalQuantity - selected) >= 0) ? true : false;
        }
      }
    }

    return false;
  }
}
