<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav mr-auto" style="position: relative;">
    <li class="nav-item d-none d-md-block">
        <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light"
            href="javascript:void(0)">
            <i class="mdi mdi-menu font-24"></i>
        </a>
    </li>
    <li class="nav-item d-none d-md-block">
        <aside class="left-sidebar" [attr.data-sidebarbg]="'skin5'" style="position: absolute;">
            <!-- Sidebar scroll-->
            <div class="scroll-sidebar" style="margin-top: 5px;">
                <app-sidebar [sidebar_with_tour]="true"></app-sidebar>
            </div>
            <!-- End Sidebar scroll-->
        </aside>
    </li>
</ul>

<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
    <!-- FAQs -->
    <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark"
            href="https://intercom.help/2-step-reviews/en" target="_blank" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" ngbTooltip="FAQs" placement="left">
            <!-- <i class="mdi mdi-help-circle font-24"></i> -->
            <mat-icon style="vertical-align: sub;">help</mat-icon>
        </a>
    </li>
    <!-- ============================================================== -->
    <!-- End FAQs -->
    <!-- ============================================================== -->
    <!-- Comment -->
    <!-- ============================================================== -->
    <li class="nav-item dropdown" ngbDropdown placement="bottom-right" [autoClose]="'outside'">
        <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" href="javascript:void(0)"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="getNotifications(true)">
            <mat-icon [matBadge]="(appService?.new_notifications > 99) ? '99+' : appService?.new_notifications"
                matBadgeColor="accent" matBadgePosition="below after"
                [matBadgeHidden]="(appService?.new_notifications <= 0)" style="vertical-align: sub;">
                {{ (appService?.new_notifications > 0) ? 'notifications_active' : 'notifications' }}
            </mat-icon>
        </a>
        <div class="dropdown-menu dropdown-menu-right mailbox animated bounceInDown" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="bg-success"></span>
            </span>
            <ul class="list-style-none">
                <li>
                    <div class="drop-title bg-success text-white">
                        <h4 class="m-b-0 m-t-5">
                            {{ (appService?.new_notifications) ? appService?.new_notifications : '0' }} New</h4>
                        <span class="font-light">Notifications</span>
                    </div>
                </li>
                <li>
                    <div class="message-center notifications" [perfectScrollbar]="config">
                        <a href="javascript:void(0)" class="message-item"
                            *ngFor="let notification of notifications; let i = index">
                            <span [class]="'btn btn-circle btn-' +  utilService.colorClassName(notification?.titulo)"
                                (click)="openNotificationModal(notificationModal, notification, i)">
                                <i [class]="utilService.iconNotifications(notification?.titulo)"></i>
                            </span>
                            <span class="mail-contnet"
                                (click)="openNotificationModal(notificationModal, notification, i)">
                                <h5 class="message-title" [style.color]="notification.status ? '' : '#a1aab2'">
                                    {{ notification?.titulo }}</h5>
                                <span class="mail-desc">{{ notification?.texto | slice:0:30 }}...</span>
                                <span class="time">{{ notification?.fecha | date: 'fullDate'}}</span>
                            </span>
                            <span class="ml-auto font-12 text-muted" (click)="deleteNotification(notification,i)">
                                <i class="mdi mdi-close"></i>
                            </span>
                        </a>
                        <br>
                        <div class="d-flex justify-content-center bg-light mb-3 text-white"
                            *ngIf="notifications?.length">
                            <button type="button" class="btn btn-primary btn-circle btn-sm" *ngIf="last_page == false"
                                (click)="getNotifications(false)">
                                <i class="mdi mdi-refresh"></i>
                            </button>
                        </div>
                    </div>
                </li>
                <li>
                    <a class="nav-link text-center m-b-5" style="cursor: pointer;" (click)="checkAllNotifications()">
                        <strong style="color: #252531;">Mark all as read</strong>&nbsp;
                        <i class="fa fa-angle-right" style="color: #252531;"></i>
                    </a>
                </li>
            </ul>
        </div>
    </li>
    <!-- ============================================================== -->
    <!-- End Comment -->
    <!-- ============================================================== -->

    <!-- User profile and search -->
    <!-- ============================================================== -->
    <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
            href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            style="display: flex; align-items: center;">
            <ngx-avatar [name]="usuarioStorage.get()?.nombre" size="40" [bgColor]="'#252531'"
                [borderColor]="'white'"></ngx-avatar>
        </a>
        <div class="dropdown-menu dropdown-menu-right user-dd animated flipInY" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="bg-primary"></span>
            </span>
            <div class="d-flex no-block align-items-center p-15 bg-primary text-white m-b-10">
                <div class="">
                    <ngx-avatar [name]="usuarioStorage.get()?.nombre" size="40" [bgColor]="'#252531'"
                        [borderColor]="'white'"></ngx-avatar>
                </div>
                <div class="m-l-10">
                    <h4 class="m-b-0">{{ usuarioStorage.get()?.nombre }}</h4>
                    <p class=" m-b-0">{{ usuarioStorage.get()?.email }}</p>
                </div>
            </div>
            <a class="dropdown-item" href="javascript:void(0)" routerLink="/users/profile">
                <i class="ti-user m-r-5 m-l-5"></i> My Profile</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">
                <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
            <div class="dropdown-divider"></div>
        </div>
    </li>
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
</ul>

<!--begin::Delete Confirmation Modal-->
<ng-template #notificationModal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Notification</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <b>{{ notification?.titulo }}</b>
        <br>
        <br>
        <span class="m--font-danger">{{ notification?.texto }}</span>
        <br>
        <br>
        <span class="time">{{ notification?.fecha | date: 'fullDate'}}</span>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success m-btn--air" (click)="c('aceptar')"
            *ngIf="notification?.link || notification?.payload">View</button>
    </div>
</ng-template>
<!--end::Modal-->