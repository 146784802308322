export const IconLetter = {
    a: 'ti-link',
    b: 'ti-link',
    c: 'ti-link',
    d: 'ti-link',
    e: 'ti-link',
    f: 'ti-link',
    g: 'ti-link',
    h: 'ti-calendar',
    i: 'ti-calendar',
    j: 'ti-calendar',
    k: 'ti-calendar',
    l: 'ti-calendar',
    m: 'ti-calendar',
    n: 'ti-calendar',
    o: 'ti-settings',
    p: 'ti-settings',
    q: 'ti-settings',
    r: 'ti-settings',
    s: 'ti-settings',
    t: 'ti-settings',
    u: 'ti-settings',
    v: 'ti-user',
    w: 'ti-user',
    x: 'ti-user',
    y: 'ti-user',
    z: 'ti-user'
};