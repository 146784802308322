import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { AuthGuard } from "./authentication/auth.guard";

export const Approutes: Routes = [
  {
    path: 'change-password',
    loadChildren: './changepassword/changepassword.module#ChangepasswordModule',
  },
  {
    path: 'forgot_password',
    loadChildren: './forgot-password/forgot-password.module#ForgotPasswordModule',
  },
  {
    path: 'gmb-analysis',
    loadChildren: './gmb-analysis/gmb-analysis.module#GmbAnalysisModule',
  },
  {
    path: 'subscribe',
    loadChildren: './subscribe/subscribe.module#SubscribeModule',
  },
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'users',
        loadChildren: './usuario/usuario.module#UsuarioModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'reviews',
        loadChildren: './review/review.module#ReviewModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'sms',
        loadChildren: './sms/sms.module#SmsModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'blacklist',
        loadChildren: './blacklist/blacklist.module#BlacklistModule',
        canActivate: [AuthGuard],
        data: { hideRoles: ['ROLE_EMPLOYEE'] }
      },
      {
        path: 'templates',
        loadChildren: './template/template.module#TemplateModule',
        canActivate: [AuthGuard],
        data: { hideRoles: ['ROLE_EMPLOYEE', 'ROLE_MANAGER'] }
      },
      {
        path: 'leaderboard',
        loadChildren: './leaderboard/leaderboard.module#LeaderboardModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'faqs',
        loadChildren: './faq/faq.module#FaqModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'companies',
        loadChildren: './company/company.module#CompanyModule',
        canActivate: [AuthGuard],
        data: { hideRoles: ['ROLE_EMPLOYEE', 'ROLE_MANAGER', 'ROLE_ADMIN'] }
      },
      {
        path: 'messenger',
        loadChildren: './chat/chat.module#ChatModule',
        canActivate: [AuthGuard],
        data: { hideRoles: ['ROLE_EMPLOYEE', 'ROLE_SUPER', 'ROLE_ENTERPRISE'] }
      },
      {
        path: 'customers',
        loadChildren: './customer/customer.module#CustomerModule',
        canActivate: [AuthGuard],
        data: { hideRoles: ['ROLE_MANAGER'] }
      },
      {
        path: 'auth',
        loadChildren: './authorization/authorization.module#AuthorizationModule',
        canActivate: [AuthGuard],
        data: { hideRoles: ['ROLE_EMPLOYEE', 'ROLE_MANAGER'] }
      },
      {
        path: 'gmb',
        loadChildren: './gmb/gmb.module#GmbModule',
        canActivate: [AuthGuard],
        data: { hideRoles: ['ROLE_SUPER', 'ROLE_ENTERPRISE', 'ROLE_EMPLOYEE', 'ROLE_MANAGER'] }
      },
      {
        path: 'upgrade',
        loadChildren: './upgrade/upgrade.module#UpgradeModule',
        canActivate: [AuthGuard],
        data: { hideRoles: ['ROLE_SUPER'] }
      },
      {
        path: 'billing',
        loadChildren: './billing/billing.module#BillingModule',
        canActivate: [AuthGuard],
        data: { hideRoles: ['ROLE_EMPLOYEE', 'ROLE_MANAGER', 'ROLE_SUPER'] }
      },
      {
        path: 'industry',
        loadChildren: './industry/industry.module#IndustryModule',
        canActivate: [AuthGuard],
        data: { hideRoles: ['ROLE_EMPLOYEE', 'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_ENTERPRISE'] }
      },
      {
        path: 'promo-code',
        loadChildren: './promo_code/promo_code.module#PromoCodeModule',
        canActivate: [AuthGuard],
        data: { hideRoles: ['ROLE_EMPLOYEE', 'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_ENTERPRISE'] }
      },
      {
        path: 'accounts',
        loadChildren: './account/account.module#AccountModule',
        canActivate: [AuthGuard],
        data: { hideRoles: ['ROLE_EMPLOYEE', 'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_ENTERPRISE'] }
      },
      // {
      //   path: 'nms-template',
      //   loadChildren: './nms_template/nms_template.module#NmsTemplateModule',
      //   canActivate: [AuthGuard],
      //   data: { hideRoles: ['ROLE_EMPLOYEE', 'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_ENTERPRISE'] }
      // },
      // {
      //   path: 'nms-notification',
      //   loadChildren: './nms_notification/nms_notification.module#NmsNotificationModule',
      //   canActivate: [AuthGuard],
      //   data: { hideRoles: ['ROLE_EMPLOYEE', 'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_ENTERPRISE'] }
      // },
    ]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren:
          './authentication/authentication.module#AuthenticationModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/authentication/404'
  }
];
