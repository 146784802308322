import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lm-upgrade-btn',
  templateUrl: './lm-upgrade-btn.component.html',
  styleUrls: ['./lm-upgrade-btn.component.scss']
})
export class LmUpgradeBtnComponent {

  @Input() returnUrl?: String;

  constructor(
    private router: Router
  ) { }

  upgrade() {
    this.router.navigate(['/upgrade/plan'], { queryParams: { returnUrl: this.returnUrl } });
  }
}
