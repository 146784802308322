import { Inject, Injectable } from '@angular/core';
import { UsuarioService } from '../../../usuario/usuario.service';
import { NotificationService } from '../../../notification/notification.service';
import { ToastrService } from 'ngx-toastr';
import { MessagingService } from '../../messaging/messaging.service';
import { BehaviorSubject } from 'rxjs';
import { UpgradeAccountModel } from 'src/app/upgrade/model/upgrade-account.model';
import { CompanyService } from 'src/app/company/company.service';
import { AuthService } from 'src/app/authentication/auth.service';
import { CompanyStorage } from 'src/app/company/company.storage';
import { Company } from 'src/app/company/company';

@Injectable()
export class AppService {
    public sidebarpos: any = 'fixed';
    public new_notifications: any;

    private upgradeAccount: BehaviorSubject<UpgradeAccountModel> = new BehaviorSubject<UpgradeAccountModel>(null);
    upgradeAccount$ = this.upgradeAccount.asObservable();

    constructor(
        @Inject('Parameters') private parameters: string,
        public usuarioService: UsuarioService,
        public notificationService: NotificationService,
        public toastr: ToastrService,
        public messagingService: MessagingService,
        private companyService: CompanyService,
        private authService: AuthService,
        private companyStorage: CompanyStorage
    ) {
        this.subscribeNotifications();

        this.getUpgradeAccount();
    }

    setUpgradeAccount(upgradeAccount: UpgradeAccountModel) {
        this.upgradeAccount.next(upgradeAccount);
    }

    subscribeNotifications() {
        this.messagingService.receiveMessage();
    }

    hayNuevasNotificaticaciones() {
        this.notificationService.newNotifications().subscribe((response) => {
            this.new_notifications = response.data;
        }, (error) => {
            this.toastr.error(error.error.error.message, 'Error!');
        });
    }

    getUpgradeAccount() {
        if (this.authService.isLoggedIn()) {
            const company: Company = this.companyStorage.get();

            if (company)
                this.getPlan(company?.id);
        }
    }

    private getPlan(company_id: any) {
        this.companyService.getPlan(company_id).subscribe((response) => {
            const data = response?.data;
            const upgradeAccountModel: UpgradeAccountModel = new UpgradeAccountModel(data);

            this.setUpgradeAccount(upgradeAccountModel);
        });
    }
}