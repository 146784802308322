import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarModule } from 'ngx-avatar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HideByRolesDirective } from "./directives/hide-by-roles.directive";
import { ShowByRolesDirective } from "./directives/show-by-roles.directive";
import { NgxMaskModule } from 'ngx-mask'
import { LmMaskDirective } from "./directives/lm-mask.directive";
import { ArchwizardModule } from "angular-archwizard";
import { PaginatorComponent } from "./component/paginator/paginator.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CalendarComponent } from "./component/calendar/calendar.component";
import { NgxDateRangePickerModule } from "ngx-daterangepicker";
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SlideToggleModule } from 'ngx-slide-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSortModule } from '@angular/material/sort';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatStepperModule } from '@angular/material/stepper';
import { NgxClipboardModule } from '@ngx-tiny/clipboard';
import { NgPipesModule } from 'ngx-pipes';
import { MatTabsModule } from '@angular/material/tabs';
import { LmUpgradeBtnComponent } from '../upgrade/component/lm-upgrade-button/lm-upgrade-btn.component';
import { SelectPlanComponent } from '../upgrade/component/select-plan/select-plan.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { InputSelectCompanyComponent } from './component/input-select-company/input-select-company.component';
import { OptionsScrollDirective } from './directives/option-scroll.directuve';
import { TermsAndConditions } from './component/terms_and_conditions/terms_and_conditions.component';
// import { NgxSummernoteModule } from 'ngx-summernote';

@NgModule({
  imports: [
    CommonModule,
    AvatarModule,
    NgxSpinnerModule,
    NgxMaskModule.forRoot(),
    ArchwizardModule,
    NgbModule,
    NgxDateRangePickerModule,
    SweetAlert2Module.forRoot(),
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    SlideToggleModule,
    MatBadgeModule,
    MatSortModule,
    MatCheckboxModule,
    MatPasswordStrengthModule.forRoot(),
    PerfectScrollbarModule,
    MatStepperModule,
    MatPasswordStrengthModule.forRoot(),
    NgxClipboardModule,
    NgPipesModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    NgxClipboardModule,
    // NgxSummernoteModule
  ],
  declarations: [
    HideByRolesDirective,
    ShowByRolesDirective,
    LmMaskDirective,
    PaginatorComponent,
    CalendarComponent,
    LmUpgradeBtnComponent,
    SelectPlanComponent,
    InputSelectCompanyComponent,
    OptionsScrollDirective,
    TermsAndConditions
  ],
  exports: [
    AvatarModule,
    NgxSpinnerModule,
    HideByRolesDirective,
    ShowByRolesDirective,
    NgxMaskModule,
    LmMaskDirective,
    ArchwizardModule,
    PaginatorComponent,
    NgbModule,
    CalendarComponent,
    NgxDateRangePickerModule,
    SweetAlert2Module,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    SlideToggleModule,
    MatBadgeModule,
    MatSortModule,
    MatCheckboxModule,
    MatPasswordStrengthModule,
    PerfectScrollbarModule,
    MatStepperModule,
    MatPasswordStrengthModule,
    NgxClipboardModule,
    NgPipesModule,
    MatTabsModule,
    LmUpgradeBtnComponent,
    SelectPlanComponent,
    ReactiveFormsModule,
    MatAutocompleteModule,
    InputSelectCompanyComponent,
    OptionsScrollDirective,
    NgxClipboardModule,
    TermsAndConditions
  ]
})
export class SharedModule { }
