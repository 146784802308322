import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export interface IAutoCompleteScrollEvent {
    autoComplete: MatAutocomplete;
    scrollEvent: Event;
}

@Directive({
    selector: 'mat-autocomplete[optionsScroll]',
    exportAs: 'optionsScroll'
})
export class OptionsScrollDirective implements OnDestroy {

    @Input() thresholdPercent = .8;
    @Output('optionsScroll') scroll = new EventEmitter<IAutoCompleteScrollEvent>();
    _onDestroy = new Subject();
    hasEmitted = false;

    constructor(public autoComplete: MatAutocomplete) {
        this.autoComplete.opened.pipe(
            tap(() => {
                /** Note: When autocomplete raises opened, panel is not yet created (by Overlay)
                    Note: The panel will be available on next tick
                    Note: The panel wil NOT open if there are no options to display
                */
                setTimeout(() => {
                    /** Note: remove listner just for safety, in case the close event is skipped. */
                    this.removeScrollEventListener();
                    this.autoComplete.panel.nativeElement
                        .addEventListener('scroll', this.onScroll.bind(this))
                });
            }),
            takeUntil(this._onDestroy)).subscribe();

        this.autoComplete.closed.pipe(
            tap(() => this.removeScrollEventListener()),
            takeUntil(this._onDestroy)).subscribe();
    }

    private removeScrollEventListener() {
        if (this.autoComplete && this.autoComplete.panel && this.autoComplete.panel.nativeElement) {
            this.autoComplete.panel.nativeElement
                .removeEventListener('scroll', this.onScroll);
        }
    }

    onScroll(event: any) {
        // Si ya se ha emitido el evento, no hacemos nada
        if (this.hasEmitted) {
            return;
        }

        // Comprobamos si el scroll ha llegado al final
        if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
            // Emitimos el evento y marcamos la variable hasEmitted como verdadera
            this.scroll.next({ autoComplete: this.autoComplete, scrollEvent: event });
            this.hasEmitted = true;
        }
    }

    resetHasEmitted() {
        this.hasEmitted = false;
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
        this.removeScrollEventListener();
    }
}