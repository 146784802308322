import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api/api.service';
import { HttpService } from '../shared/services/http/http.service';

@Injectable()
export class FcmService extends ApiService {
    constructor(protected http: HttpService) {
        super(http);
        this.url = 'api/fcm';
    }

    updateFCM(token: any, userId) {
        let params = {
            _key: userId + window.navigator.userAgent.replace(/\D+/g, ''),
            _value: token
        };
        return this.http.post(`${this.url}`, params);
    }
}
