import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ROUTES } from './menu-items';
import { Router } from '@angular/router';
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { UsuarioStorage } from 'src/app/usuario/usuario.storage';
import { RoleStorage } from '../storage/role.storage';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {

  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: any[];
  @Input() sidebar_with_tour: any;

  @ViewChild('facebookAuthModal', { static: true }) private facebookAuthModal: SwalComponent;

  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  constructor(
    private router: Router,
    public usuarioStorage: UsuarioStorage,
    private roleStorage: RoleStorage
  ) { }

  // End open close
  ngOnInit() {
    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => {
      let session_role = this.roleStorage.get();

      if (!sidebarnavItem.hideRoles.includes(session_role))
        return sidebarnavItem;
    });
  }

  hideSubMenu(sidebarnavSubItem: any) {
    if (sidebarnavSubItem.hideRoles) {
      let session_role = localStorage.getItem('role');
      let exist = sidebarnavSubItem.hideRoles.includes(session_role);

      if (exist)
        return false;
    }

    return true;
  }

  onTouchStep(color?) {

    let colors_backdrop = {
      "morado": "rgba(69, 59, 141, 0.7)",
      "verde": "rgba(79, 246, 198, 0.7)",
      "negro": "rgba(37, 37, 49, 0.7)"
    };

    if (color) {
      window.setTimeout(() => {
        $('.joyride-backdrop').each(function (key, element) {
          $(element).css("background-color", colors_backdrop[color]);
        });
      }, 5);
    }
  }

  goToSubmenu(path) {
    if (path) {
      this.router.navigate([path]);
    }
  }
}