import { Component, Output, EventEmitter, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { NgxDateRangePickerOptions } from "ngx-daterangepicker";

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
})
export class CalendarComponent implements OnInit, AfterViewInit, OnDestroy {

    @Output() rangeChange: EventEmitter<any> = new EventEmitter();
    options: NgxDateRangePickerOptions;
    public date: any;

    constructor() { }

    ngOnInit() {

        let date = new Date();

        this.options = {
            theme: 'default',
            labels: ['Start', 'End'],
            menu: [
            ],
            dateFormat: 'YYYY-MM-DD',
            outputFormat: 'YYYY-MM-DD',
            startOfWeek: 0,
            outputType: 'object',
            locale: 'en',
            date: {
                from: {
                    year: date.getFullYear(),
                    month: date.getMonth() + 1,
                    day: 1
                },
                to: {
                    year: date.getFullYear(),
                    month: date.getMonth() + 1,
                    day: date.getDate()
                }
            }
        };

        $('.calendar').hide();
    }

    ngAfterViewInit() {
        let self = this

        $("#picker").caleran(
            {
                target: $(".caleran"),
                startDate: moment().startOf("month"),
                showOn: "bottom",
                autoAlign: false,
                arrowOn: "right",
                showButtons: true,
                rangeOrientation: "vertical",
                ranges: [
                    {
                        title: "Today",
                        startDate: moment(),
                        endDate: moment()
                    },
                    {
                        title: "This month",
                        startDate: moment().startOf("month"),
                        endDate: moment().endOf("month")
                    },
                    {
                        title: "Last month",
                        startDate: moment().subtract(1, "months").startOf("month"),
                        endDate: moment().subtract(1, "months").endOf("month")
                    },
                    {
                        title: "Last 30 days",
                        startDate: moment().subtract(30, "days"),
                        endDate: moment().subtract(1, "days")
                    },
                    {
                        title: "Last 7 days",
                        startDate: moment().subtract(7, "days"),
                        endDate: moment().subtract(1, "days")
                    },
                    {
                        title: "This Year",
                        startDate: moment().startOf("year"),
                        endDate: moment()
                    },
                    {
                        title: "Last Year",
                        startDate: moment().subtract(1, "year").startOf("year"),
                        endDate: moment().subtract(1, "year").endOf("year")
                    },
                    {
                        title: "All Time",
                        startDate: moment([2016, 0, 1]),
                        endDate: moment()
                    },
                ],
                onafterselect: function (caleran, startDate, endDate) {

                    $('.value-txt').each(function (key, element) {
                        if (key == 0)
                            $(element).text(moment(startDate).format("YYYY-MM-DD"));
                        if (key == 1)
                            $(element).text(moment(endDate).format("YYYY-MM-DD"));
                    });

                    let params = {
                        'desde': moment(startDate).format("YYYY-MM-DD"),
                        'hasta': moment(endDate).format("YYYY-MM-DD")
                    };

                    self.rangeChange.emit(params);
                }
            });
    }

    ngOnDestroy() {
        $("#picker").remove();
    }
}
