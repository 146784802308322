<div class="card card-hover shadow-sm" *lmShowRoles="['ROLE_SUPER', 'ROLE_ENTERPRISE']">
  <div class="card-body">
    <div class="d-md-flex align-items-center">
      <div>
        <a href="javascript:void(0)" mat-button (click)="onNavigateToCompnies()">
          <h4>Companies</h4>
          <mat-icon>link</mat-icon>
        </a>
      </div>
      <div class="ml-auto">
      </div>
    </div>

    <form class="example-form" [formGroup]="form">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Company</mat-label>
        <input #input matInput [matAutocomplete]="auto" formControlName="company" (click)="onInputClick()">
        <mat-autocomplete #auto="matAutocomplete" #autoCompleteOptionsScroll="optionsScroll" optionsScroll
          (optionSelected)="onCompanySelected($event)" (optionsScroll)="onScroll()">
          <mat-option *ngFor="let company of companies" [value]="company">
            {{ company?.name }}
          </mat-option>
          <div *ngIf="isLoading" class="center">
            <mat-option [disabled]="true">
              <div class="spinner-border spinner-border-sm text-primary" role="status">
              </div>
            </mat-option>
          </div>
        </mat-autocomplete>
      </mat-form-field>
    </form>

  </div>
</div>