import { Component, Input, Output, EventEmitter, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent{

  @Input() collectionSize: any;
  @Input() pageSize: any;
  @Input() page: any;
  @Input() numsPerPage: any;

  @Output() filtersChange: EventEmitter<any> = new EventEmitter();
  @Output() limitChange: EventEmitter<any> = new EventEmitter();

  constructor() {}

  onPageChange(page:any) {
    this.filtersChange.emit(page);
  }

  onLimitChange(limit){
    this.limitChange.emit(limit);
  }
}
