import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { RoleStorage } from '../storage/role.storage';

@Directive({
    selector: '[lmShowRoles]'
})
export class ShowByRolesDirective {

    private hasView = false;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private roleStorage: RoleStorage
    ) { }

    @Input() set lmShowRoles(roles: any) {

        let session_role = this.roleStorage.get();

        let exist =  roles.includes(session_role);

        if(!exist){
            this.viewContainer.clear();
            this.hasView = false;
        }else{
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        }
    }
}