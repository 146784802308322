import "reflect-metadata";
import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from "typedjson";

@jsonObject
export class PlanModel {

  @jsonMember
  id: number;

  @jsonMember
  name: String;

  @jsonMember
  price: number;

  constructor(init?: Partial<PlanModel>) {
    Object.assign(this, init);
  }
}
