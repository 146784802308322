import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from 'rxjs';
import { RoleStorage } from "../shared/storage/role.storage";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        protected authService: AuthService,
        protected router: Router,
        private roleStorage: RoleStorage
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if(localStorage.getItem('is_password_changed') == '0'){
            this.router.navigate(['/change-password']);
        }

        if(next.data['hideRoles']){
            let session_role = this.roleStorage.get();
            let exist =  next.data['hideRoles'].includes(session_role);

            if(exist)
             this.router.navigate(['/authentication/404']);
        }

        const isLoggedIn = this.authService.isLoggedIn();

        const now = new Date();
        const expiresDate = new Date(localStorage.getItem('expires_date'));
        const tokenStillValid = (now < expiresDate);

        if (isLoggedIn && tokenStillValid) {
            return isLoggedIn;
        }
        else {

            let url: string = state.url;

            // not logged in so redirect to login page with the return url
            this.router.navigate(['/authentication/login'], { queryParams: { returnUrl: url } });
            return false;
        }
    }
}