<div class="card card-hover shadow-sm">
    <div class="card-body">
        <div class="d-md-flex align-items-center">
            <div>
                <h4 class="card-title">Select Date</h4>
                <h5 class="card-subtitle">Date</h5>
            </div>

            <div class="ml-auto">
            </div>
        </div>
        <ngx-daterangepicker id="picker" [options]="options" [(ngModel)]="date"></ngx-daterangepicker>
    </div>
    <input hidden readonly class="form-control caleran" placeholder="Phone" aria-label="Phone" name="calendar-input"
        aria-describedby="basic-addon1">
</div>