<nav class="sidebar-nav">
    <ul id="sidebarnav">
        <!-- First level menu -->
        <li class="sidebar-item" [ngClass]="[sidebarnavItem.ddclass]" [class.active]="showMenu === sidebarnavItem.title"
            *ngFor="let sidebarnavItem of sidebarnavItems; let i = index"
            [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
            <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">{{sidebarnavItem.title}}</div>
            <a class="sidebar-link waves-effect waves-dark"
                [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null"
                [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink;"
                (click)="addExpandClass(sidebarnavItem.title)"
                [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
                <i [ngClass]="[sidebarnavItem.icon]"></i>
                <span class="hide-menu" *ngIf="(sidebarnavItem.title == 'Dashboard') && (sidebar_with_tour)"
                    joyrideStep="firstStep" [stepContent]="firstStep" [title]="'Dashboard'"
                    (next)="onTouchStep('negro')">{{sidebarnavItem.title}}</span>
                <span class="hide-menu" *ngIf="sidebarnavItem.title == 'Your Reviews' && (sidebar_with_tour)"
                    joyrideStep="tenStep" [stepContent]="tenStep" [title]="'Your Reviews'" (prev)="onTouchStep('verde')"
                    (next)="onTouchStep('negro')">{{sidebarnavItem.title}}</span>
                <span class="hide-menu" *ngIf="sidebarnavItem.title == 'Send A Review Invite' && (sidebar_with_tour)"
                    id="fourteenStep" joyrideStep="fourteenStep" [stepContent]="fourteenStep"
                    [title]="'Send a Review invite'" (next)="onTouchStep('verde')">{{sidebarnavItem.title}}</span>
                <span class="hide-menu" *ngIf="sidebarnavItem.title == 'Leaderboard' && (sidebar_with_tour)"
                    id="fifteenStep" joyrideStep="fifteenStep" [stepContent]="fifteenStep" [title]="'Leaderboard'"
                    (prev)="onTouchStep('negro')" (next)="onTouchStep('morado')">{{sidebarnavItem.title}}</span>
                <span class="hide-menu" *ngIf="sidebarnavItem.title == 'Manage' && (sidebar_with_tour)" id="sixteenStep"
                    joyrideStep="sixteenStep" [stepContent]="sixteenStep" [title]="'Manage'"
                    (prev)="onTouchStep('verde')">{{sidebarnavItem.title}}</span>
                <span class="hide-menu"
                    *ngIf="!(sidebarnavItem.title == 'Dashboard') && !(sidebarnavItem.title == 'Your Reviews') && !(sidebarnavItem.title == 'Send A Review Invite') && !(sidebarnavItem.title == 'Leaderboard') && !(sidebarnavItem.title == 'Manage') || !(sidebar_with_tour)">{{sidebarnavItem.title}}</span>
                <ng-template #firstStep>
                    <h4 style="color: #6c7277;">Get to know your dashboard.</h4>
                    <h4 style="color: #6c7277;">Here's where you will measure your</h4>
                    <h4 style="color: #6c7277;">company's overall performance</h4>
                </ng-template>
                <ng-template #tenStep>
                    <h4 style="color: #6c7277;">Read and answer all the reviews you’ve</h4>
                    <h4 style="color: #6c7277;">gotten in a selected date range.</h4>
                </ng-template>
                <ng-template #fourteenStep>
                    <h4 style="color: #6c7277;">Enter customer’s name and phone</h4>
                    <h4 style="color: #6c7277;">number/email to send a review invite.</h4>
                </ng-template>
                <ng-template #fifteenStep>
                    <h4 style="color: #6c7277;">see your employee engagement by measuring</h4>
                    <h4 style="color: #6c7277;">their invites, reviews and success rate.</h4>
                </ng-template>
                <ng-template #sixteenStep>
                    <h4 style="color: #6c7277;">Set up and manage your team, messaging, blacklist</h4>
                    <h4 style="color: #6c7277;">and company info.</h4>
                </ng-template>
            </a>
            <!-- Second level menu -->
            <ul aria-expanded="false" class="collapse first-level" *ngIf="sidebarnavItem.submenu.length > 0"
                [ngClass]="{'in' : showMenu === sidebarnavItem.title }">
                <li class="sidebar-item" *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu"
                    [class.active]="showSubMenu === sidebarnavSubItem.title"
                    [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'active'" style="cursor: pointer">
                    <a class="sidebar-link"
                        (click)="goToSubmenu(sidebarnavSubItem.submenu.length > 0 ? null : sidebarnavSubItem.path)"
                        [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'router-link-active'"
                        [ngClass]="[sidebarnavSubItem.class]"
                        *ngIf="(!sidebarnavSubItem.extralink) && (hideSubMenu(sidebarnavSubItem)) && !((sidebarnavSubItem?.hideIsAgency == true) && (usuarioStorage.get()?.isAgency == true))"
                        (click)="addActiveClass(sidebarnavSubItem.title)">
                        <i [ngClass]="[sidebarnavSubItem.icon]"></i>
                        <span class="hide-menu" *ngIf="i == 0">{{sidebarnavSubItem.title}}</span>
                        <span class="hide-menu" *ngIf="i != 0">{{sidebarnavSubItem.title}}</span>
                    </a>
                    <!-- Third level menu -->
                    <ul aria-expanded="false" class="collapse second-level" *ngIf="sidebarnavSubItem.submenu.length > 0"
                        [ngClass]="{'in' : showSubMenu === sidebarnavSubItem.title }">
                        <li class="sidebar-item" *ngFor="let sidebarnavSubsubItem of sidebarnavSubItem.submenu"
                            routerLinkActive="active" [ngClass]="[sidebarnavSubsubItem.class]">
                            <a class="sidebar-link" [routerLink]="[sidebarnavSubsubItem.path]"
                                *ngIf="!sidebarnavSubsubItem.extralink;"
                                [routerLinkActive]="sidebarnavSubsubItem.submenu.length > 0 ? '' : 'router-link-active'">
                                <i [ngClass]="[sidebarnavSubsubItem.icon]"></i>
                                <span class="hide-menu">{{sidebarnavSubsubItem.title}}</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
</nav>

<!--start::Modal Swal-->
<swal #facebookAuthModal title="Faceboook Authorized">
    <form *swalPartial>
        <div class="form-group">
            <div class="col-12">
                <div class="d-flex no-block justify-content-center align-items-center">
                    <span class="db"><img src="assets/images/fb-page-logo.png" alt="logo"
                            style="width: 120px;height: 100px" /></span>
                </div>
            </div>
        </div>
    </form>
</swal>
<!--end::Modal Swal-->