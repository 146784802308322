import { Component } from '@angular/core';
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";

@Component({
  selector: 'terms-and-conditions',
  templateUrl: './terms_and_conditions.component.html',
  styleUrls: ['./terms_and_conditions.component.scss']
})
export class TermsAndConditions {
  public config: PerfectScrollbarConfigInterface = {};
}
