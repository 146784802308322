import { Injectable } from '@angular/core';
import { Usuario } from './usuario';

@Injectable({
    providedIn: 'root'
})
export class UsuarioStorage {

    constructor() {
    }

    save(usuario: Usuario) {
        localStorage.setItem('user', JSON.stringify(usuario));
    }

    get(): Usuario {
        return JSON.parse(localStorage.getItem('user'));
    }

    remove() {
        localStorage.removeItem('user');
    }
}
