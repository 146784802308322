import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api/api.service';
import { HttpService } from '../shared/services/http/http.service';
import { Observable } from "rxjs/Rx";

@Injectable()
export class OfficeService extends ApiService {
    constructor(protected http: HttpService) {
        super(http);
        this.url = 'api/offices';
    }

    collect(id: any): Observable<any> {
        return this.http.get(`${this.url}/collect/${id}`);
    }

    updateSocial(params: any, id: any): Observable<any> {
        return this.http.put(`${this.url}/${id}/social`, super.serialize(params));
    }

    updateMessenger(params: any, id: any): Observable<any> {
        return this.http.put(`${this.url}/${id}/social/messenger`, super.serialize(params));
    }
}
