import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/internal/operators';
import { AuthService } from 'src/app/authentication/auth.service';
import { CompanyStorage } from 'src/app/company/company.storage';
import { Company } from 'src/app/company/company';
import { UtilService } from '../services/app/util.service';
import { RoleStorage } from '../storage/role.storage';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

    constructor(
        public spinner: NgxSpinnerService,
        protected authService: AuthService,
        private companyStorage: CompanyStorage,
        private utilService: UtilService,
        private roleStorage: RoleStorage
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let company: Company = this.companyStorage.get();

        let requestWithCompanyProperty = req.clone({
            setParams: {
                company_id: (this.roleStorage.isSuperiorPermission() && company) ? company?.id?.toString() : null,
            },
        });

        let params = new HttpParams({ fromString: requestWithCompanyProperty.params.toString() });
        params = this.utilService.removeNullValuesFromQueryParams(params);

        if (params.get("with_company") == "0")
            params['map'].delete("company_id");

        params['map'].delete("with_company");


        requestWithCompanyProperty = requestWithCompanyProperty.clone({
            params
        });

        return next.handle(requestWithCompanyProperty).pipe(
            tap(evt => { }),
            catchError((err: HttpErrorResponse) => {
                if (err['error']) {
                    if (err['error']['error'] == 'invalid_grant') {
                        this.authService.logout();
                    }
                }
                return throwError(err);
            })
        );
    }
}