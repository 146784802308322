<div class="row">
    <div class="col-12 text-center">
        <br>
        <h3 class="card-title text-primary">Become the OBVIOUS choice with 2 Step Reviews</h3>
        <br>
        <p>Choose a review capture solution to get started:</p>
    </div>
</div>
<div class="row">
    <div class="col-lg-4 col-xs-4 col-md-4 col-sm-12">
        <div class="col-12 m-t-30">
            <div class="card card-hover select-plan-opacity-card">
                <div class="card-header bg-secondary text-center select-plan-opacity-card-hdeader">
                    <h4 class="card-title text-primary">Start</h4>
                </div>
                <div class="card-body text-center">
                    <div class="p-15 text-center">
                        <h3 class="display-8 position-relative m-t-5 m-b-5">
                            <div>
                                <span class="select-plan-dolar-up">$</span>49<small>/month</small>
                            </div>
                        </h3>
                    </div>
                    <br>
                    <p class="card-text select-plan-bold">Includes 1 users</p>
                    <p class="card-text select-plan-bold">10 review requests/month</p>
                    <p class="card-text select-plan-bold">Leaderboard</p>
                    <p class="card-text select-plan-bold">Mobile app</p>
                    <p class="card-text select-plan-bold">&nbsp;</p>
                    <p class="card-text select-plan-bold">&nbsp;</p>
                    <p class="card-text select-plan-bold">&nbsp;</p>
                    <br>
                    <button class="btn btn-primary" style="border-radius: 20px;" (click)="onPlanSelected(2)">
                        <div *ngIf="!(selectedPlan == 2)">CHOOSE PLAN</div>
                        <i *ngIf="selectedPlan == 2" class="mdi mdi-check"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-xs-4 col-md-4 col-sm-12">
        <div class="col-12 m-t-30">
            <div class="card card-hover select-plan-opacity-card">
                <div class="card-header bg-secondary text-center select-plan-opacity-card-hdeader">
                    <h4 class="card-title text-primary">Grow</h4>
                </div>
                <div class="card-body text-center">
                    <div class="p-15 text-center">
                        <h3 class="display-8 position-relative m-t-5 m-b-5">
                            <div>
                                <span class="select-plan-dolar-up">$</span>99<small>/month</small>
                            </div>
                        </h3>
                    </div>
                    <br>
                    <p class="card-text select-plan-bold">Everything in <b>Start</b> plus:</p>
                    <p class="card-text select-plan-bold">Includes 10 users</p>
                    <p class="card-text select-plan-bold">150 review requests/month</p>
                    <p class="card-text select-plan-bold">Competitor Analysis</p>
                    <p class="card-text select-plan-bold">Google Business Profile Audit</p>
                    <p class="card-text select-plan-bold" style="margin-bottom: 0px;">Automatically share reviews on</p>
                    <p class="card-text select-plan-bold">social media (Facebook, LinkeIn, Instagram, Twitter)</p>
                    <br>
                    <button class="btn btn-primary" style="border-radius: 20px;" (click)="onPlanSelected(3)">
                        <div *ngIf="!(selectedPlan == 3)">CHOOSE PLAN</div>
                        <i *ngIf="selectedPlan == 3" class="mdi mdi-check"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-xs-4 col-md-4 col-sm-12">
        <div class="col-12 m-t-30">
            <div class="card card-hover select-plan-opacity-card">
                <div class="card-header bg-secondary text-center select-plan-opacity-card-hdeader">
                    <h4 class="card-title text-primary">Accelerate</h4>
                </div>
                <div class="card-body text-center">
                    <div class="p-15 text-center">
                        <h3 class="display-8 position-relative m-t-5 m-b-5">
                            <div>
                                <span class="select-plan-dolar-up">$</span>149<small>/month</small>
                            </div>
                        </h3>
                    </div>
                    <br>
                    <p class="card-text select-plan-bold">Everything in <b>Grow</b> plus:</p>
                    <p class="card-text select-plan-bold">Unlimited users</p>
                    <p class="card-text select-plan-bold">500 review requests/month</p>
                    <p class="card-text select-plan-bold">Messaging Dashboard</p>
                    <p class="card-text select-plan-bold">Mass review request (100/week)</p>
                    <p class="card-text select-plan-bold">Upload customer list</p>
                    <p class="card-text select-plan-bold">Customer success manager</p>
                    <br>
                    <button class="btn btn-primary" style="border-radius: 20px;" (click)="onPlanSelected(4)">
                        <div *ngIf="!(selectedPlan == 4)">CHOOSE PLAN</div>
                        <i *ngIf="selectedPlan == 4" class="mdi mdi-check"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>