import { FeatureType } from "./feature-type";

export const featuresMap = [
    {
        name: "invites_per_month",
        type: FeatureType.Quota,
    },
    {
        name: "max_users",
        type: FeatureType.Quota,
    },
    {
        name: "competitor_analysis",
        type: FeatureType.Functionality,
    },
    {
        name: "gmb_audit",
        type: FeatureType.Functionality,
    },
    {
        name: "post_on_social",
        type: FeatureType.Functionality,
    },
    {
        name: "mass_week_invites",
        type: FeatureType.Quota,
    },
    {
        name: "upload_customer_csv",
        type: FeatureType.Functionality,
    },
    {
        name: "platform_messenger",
        type: FeatureType.Functionality,
    },
    {
        name: "max_reviews",
        type: FeatureType.Quota
    }
];