<div class="row">
    <ngb-pagination
            [collectionSize]="collectionSize"
            [pageSize]="pageSize"
            [(page)]="page"
            [maxSize]="5"
            [rotate]="true"
            [boundaryLinks]="true"
            (pageChange)="onPageChange($event)"
    ></ngb-pagination>&nbsp;
    <select
        class="form-control"
        name="limit"
        style="width: auto"
        (change)="onLimitChange($event.target.value)"
    >
        <option *ngFor="let numPerPage of numsPerPage" [value]="numPerPage" [selected]="numPerPage == pageSize">
            {{ numPerPage }}
        </option>
    </select>
</div>