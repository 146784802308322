import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api/api.service';
import { HttpService } from '../shared/services/http/http.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthorizationService extends ApiService {
    constructor(protected http: HttpService) {
        super(http);
        this.url = 'api/authorize';
    }

    health(params?: any): Observable<any> {
        return this.http.get(`${this.url}/health`, (params) ? this.serialize(params) : null, false, true);
    }

    getUrl(params: any, withOutCompany?: boolean): Observable<any> {
        return this.http.get(`${this.url}/auth-url`, this.serialize(params), false, (withOutCompany) ? true : false);
    }

    deleteAuthorization(company_id: any, platform_id: any): Observable<any> {
        return this.http.delete(`${this.url}/${company_id}?platform_id=${platform_id}`);
    }

    authorizeGoogle(params) {
        return this.http.post(`${this.url}/google`, params);
    }

    authorizeTwitter(params) {
        return this.http.post(`${this.url}/twitter`, params);
    }

    authorizeLinkedin(params) {
        return this.http.post(`${this.url}/linkedin`, params);
    }

    authorizeFacebook(params) {
        return this.http.post(`${this.url}/facebook`, params);
    }

    getStatus(google_location_id: String) {

        let params = {
            google_location_id: google_location_id
        };

        return this.http.get(`${this.url}/health`, params);
    }
}