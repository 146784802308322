import { Injectable } from '@angular/core';
import { ColorLetter } from '../../util/color.letter';
import { NameColorLetter } from '../../util/name-color.letter';
import { IconLetter } from '../../util/icon.letter';
import { FeatureModel } from 'src/app/upgrade/model/feature';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class UtilService {
    constructor() { }

    colorClass(key: any) {
        if (key)
            return (ColorLetter[key[0].toLowerCase()]) ? ColorLetter[key[0].toLowerCase()] : "#4FF6C6";
        return "#4FF6C6";
    }

    colorClassName(key: any) {
        if (key)
            return (NameColorLetter[key[0].toLowerCase()]) ? NameColorLetter[key[0].toLowerCase()] : "primary";
        return "primary";
    }

    iconNotifications(key: any) {
        if (key)
            return (IconLetter[key[0].toLowerCase()]) ? IconLetter[key[0].toLowerCase()] : "ti-settings";
        return "ti-settings";
    }

    intlRound(numero, decimales = 2, usarComa = false) {
        let opciones = {
            maximumFractionDigits: decimales,
            useGrouping: false
        };

        let coma = usarComa ? "es" : "en";
        return new Intl.NumberFormat(coma, opciones).format(numero);
    }

    round5(x) {
        return Math.ceil(x / 5) * 5;
    }

    removeItemFromArr(arr, item) {
        var i = arr.indexOf(item);

        if (i !== -1) {
            arr.splice(i, 1);
        }
    }

    generatePassword(length) {
        var chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOP1234567890";
        var pass = "";
        for (var x = 0; x < length; x++) {
            var i = Math.floor(Math.random() * chars.length);
            pass += chars.charAt(i);
        }
        return pass;
    }

    isLink(link) {
        let urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        let result = urlRegex.exec(link);

        return (!result) ? false : true;
    }

    getPhoneMask(phone) {
        let phoneMask = '+';
        if (phone?.length == 10)
            phoneMask += '1';
        phoneMask += phone;
        return phoneMask;
    }

    getFeatureValueByName(name: string, features: FeatureModel[]) {
        const value = features?.find(f => f?.name == name)?.val;

        if (value == null)
            return null;

        if (value == -1)
            return "Unlimited";

        return value;
    }

    removeNullValuesFromQueryParams(params: HttpParams): HttpParams {
        const paramsKeysAux = params.keys();
        paramsKeysAux.forEach((key) => {
            const value = params.get(key);
            if (value === null || value === "null" || value === undefined || value === "undefined" || value === '') {
                params['map'].delete(key);
            }
        });

        return params;
    }

    randomPassword() {
        let caps = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let numbers = "1234567890";
        let chars = "abcdefghijklmnopqrstuvwxyz1234567890";
        let symbol = "!@#$%^&*()-+<>";

        let pass = "";

        for (let x = 0; x < 2; x++) {
            let i = Math.floor(Math.random() * caps.length);
            pass += caps[i];
        }

        for (let x = 0; x < 3; x++) {
            let i = Math.floor(Math.random() * numbers.length);
            pass += numbers[i];
        }

        for (let x = 0; x < 3; x++) {
            let i = Math.floor(Math.random() * chars.length);
            pass += chars[i];
        }

        for (let x = 0; x < 3; x++) {
            let i = Math.floor(Math.random() * symbol.length);
            pass += symbol[i];
        }

        return pass;
    }

    escapeCharacterHtml(str) {
        str = str.replace(/&gt;/g, ">");
        str = str.replace(/&lt;/g, "<");
        str = str.replace(/&nbsp;/g, " ");
        return str;
    }

    tieneCodigoPais(numero) {
        const regex = /^\+(\d+)/; // Expresión regular para buscar el signo de más y el primer número después de él
        
        // Comprobamos si el número comienza con el signo de más (+) y un número
        return regex.test(numero);
      }
}
