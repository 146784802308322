import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[lmHideRoles]'
})
export class HideByRolesDirective {

    private hasView = false;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) { }

    @Input() set lmHideRoles(roles: any) {

        let session_role = localStorage.getItem('role');

        let exist =  roles.includes(session_role);

        if(!exist){
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        }else{
            this.viewContainer.clear();
            this.hasView = false;
        }
    }
}