import { Injectable } from '@angular/core';
import { Company } from './company';

@Injectable({
    providedIn: 'root'
})
export class CompanyStorage {

    constructor() {
    }

    save(company: Company) {

        if (company)
            localStorage.setItem('company', JSON.stringify(company));
    }

    get() {
        return JSON.parse(localStorage.getItem('company'));
    }

    remove() {
        localStorage.removeItem('company');
    }
}
