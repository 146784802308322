<!-- ============================================================== -->
<!-- Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
<div class="page-breadcrumb">
    <div class="row">
        <div class="col-12 align-self-center">
            <h4 class="page-title" style="display: inline">{{pageInfo?.title}}</h4>

            <a href="javascript:void(0)" mat-button *ngIf="!((localUrlStorage.get() == '/users/signup-enterprise') || (localUrlStorage.get() == '/accounts'))"
                [routerLink]="roleStorage.isSuperiorPermission() ? '/companies' : '/dashboard'">
                <h4 class="page-title float-right" style="display: inline">{{ companyStorage.get()?.name }}</h4>
            </a>
            <div class="d-flex align-items-center">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <ng-template ngFor let-url [ngForOf]="pageInfo?.urls" let-last="last">
                            <li class="breadcrumb-item" *ngIf="!last" [routerLink]="url.url">
                                <a href='javascript:void(0)'>{{url.title}}</a>
                            </li>
                            <li class="breadcrumb-item active" *ngIf="last">{{url.title}}</li>
                        </ng-template>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<!-- ============================================================== -->
<!-- End Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->