import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { MessagingService } from "../shared/messaging/messaging.service";
import { ApiService } from '../shared/services/api/api.service';
import { HttpService } from '../shared/services/http/http.service';
import { Observable } from 'rxjs';
import { CompanyStorage } from '../company/company.storage';
import { UsuarioStorage } from '../usuario/usuario.storage';
import { RoleStorage } from '../shared/storage/role.storage';

@Injectable()
export class AuthService extends ApiService {

    constructor(
        protected http: HttpService,
        public router: Router,
        public messagingService: MessagingService,
        private companyStorage: CompanyStorage,
        private usuarioStorage: UsuarioStorage,
        private roleStorage: RoleStorage
    ) {
        super(http);
        this.url = 'api';
    }

    public login(resource?: any): Observable<any> {
        resource = this.serialize(resource);
        return this.http.post(`oauth/v2/token`, resource);
    }

    public logout() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('expires_date');
        localStorage.removeItem('is_password_changed');
        this.roleStorage.remove();
        this.companyStorage.remove();
        this.usuarioStorage.remove();
        this.messagingService.deleteToken();
        this.router.navigate(['/authentication/login']);
    }

    public getAccessToken() {
        return localStorage.getItem('access_token');
    }

    public isLoggedIn(): boolean {
        return this.getAccessToken() !== null;
    }

    public calculateTokenExpiresDateTime(expires_in): any {
        let now = new Date();
        let expiresDate = new Date(now.getTime() + expires_in * 1000);

        return expiresDate;
    }

    public signup(resource?: any): Observable<any> {
        resource = this.serialize(resource);
        return this.http.post(`${this.url}/signup`, resource, true);
    }
}
