import { Component, OnInit, Output, EventEmitter, forwardRef, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CompanyStorage } from 'src/app/company/company.storage';
import { Company } from 'src/app/company/company';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CompanyService } from 'src/app/company/company.service';
import { OptionsScrollDirective } from '../../directives/option-scroll.directuve';
import { AppService } from '../../services/app/app.service';

@Component({
  selector: 'app-input-select-company',
  templateUrl: './input-select-company.component.html',
  styleUrls: ['./input-select-company.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSelectCompanyComponent),
      multi: true
    }
  ]
})
export class InputSelectCompanyComponent implements OnInit {

  @Output() navigateToCompnies: EventEmitter<any> = new EventEmitter();
  @Output() onSelectCompany: EventEmitter<Company> = new EventEmitter();

  @ViewChild('input') input: ElementRef;
  @ViewChild('autoCompleteOptionsScroll') autoCompleteOptionsScroll: OptionsScrollDirective;

  public form: FormGroup;
  public company: Company;
  public companies: Company[] = [];
  public isLoading = false;

  public filters: any = {
    limit: 10,
    offset: 0,
    q: '',
    sort: '',
  };

  constructor(
    private companyStorage: CompanyStorage,
    public fb: FormBuilder,
    private companyService: CompanyService,
    private appService: AppService
  ) {
    this.form = fb.group({
      company: [],
    });
  }

  ngOnInit() {
    const company: Company = this.companyStorage.get();
    this.company = company;
    this.form.controls.company.setValue(company?.name);
    this.subscribeInputSearch();
  }

  onNavigateToCompnies() {
    this.navigateToCompnies.emit();
  }

  onCompanySelected(event: MatAutocompleteSelectedEvent) {
    const company: Company = event.option.value;
    this.company = company;
    this.form.controls.company.setValue(company?.name);
    this.input.nativeElement.blur();
    this.companyStorage.save(company);
    this.onSelectCompany.emit(company);
    this.appService.getUpgradeAccount();
  }

  getCompanies() {

    this.isLoading = true;

    this.companyService.listCompanies(this.filters).subscribe((response) => {

      const companies = response.data.results;

      this.isLoading = false;
      companies.forEach(company => {
        this.companies.push(company);
      });

      if (companies?.length > 0)
        this.resetScrollAutocomplete();

    }, (error) => {
      this.isLoading = false;
      this.resetScrollAutocomplete();
    });
  }

  onInputClick() {
    if (this.companies.length == 0) {
      const company: Company = this.companyStorage.get();
      this.filters.offset = 0;
      this.filters.q = company?.name;
      this.companies = [];
      this.getCompanies();
    }

  }

  onScroll() {
    this.filters.offset++;
    this.getCompanies();
  }

  subscribeInputSearch() {
    this.form.controls.company.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(companyName => {
        this.companies = [];
        this.filters.q = companyName;
        this.filters.offset = 0;
        this.getCompanies();
      });
  }

  resetScrollAutocomplete() {
    this.autoCompleteOptionsScroll.resetHasEmitted();
  }
}
