export const ColorLetter = {
    a: '#4FF6C6',
    b: '#4FF6C6',
    c: '#4FF6C6',
    d: '#4FF6C6',
    e: '#4FF6C6',
    f: '#4FF6C6',
    g: '#4FF6C6',
    h: '#453B8D',
    i: '#453B8D',
    j: '#453B8D',
    k: '#453B8D',
    l: '#453B8D',
    m: '#453B8D',
    n: '#453B8D',
    o: '#453B8D',
    p: '#252531',
    q: '#252531',
    r: '#252531',
    s: '#252531',
    t: '#252531',
    u: '#252531',
    v: '#252531',
    w: '#252531',
    x: '#252531',
    y: '#252531',
    z: '#252531'
};
