import "reflect-metadata";
import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from "typedjson";
import { FeatureType } from "./feature-type";
import { featuresMap } from "./features-map";

@jsonObject
export class FeatureModel {

  @jsonMember
  id: number;

  @jsonMember
  name: String;

  @jsonMember
  val: number;

  @jsonMember
  type: FeatureType;

  constructor(init?: Partial<FeatureModel>) {
    Object.assign(this, init);

    this.mapFeatureType();
  }

  private mapFeatureType() {
    this.type = featuresMap.find(f => f.name == this.name)?.type;
  }
}