export const NameColorLetter = {
    a: 'success',
    b: 'success',
    c: 'success',
    d: 'success',
    e: 'success',
    f: 'success',
    g: 'success',
    h: 'success',
    i: 'secondary',
    j: 'secondary',
    k: 'secondary',
    l: 'secondary',
    m: 'secondary',
    n: 'secondary',
    o: 'secondary',
    p: 'primary',
    q: 'primary',
    r: 'primary',
    s: 'primary',
    t: 'primary',
    u: 'primary',
    v: 'primary',
    w: 'primary',
    x: 'primary',
    y: 'primary',
    z: 'primary'
};


